// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HqWpx{display:flex;gap:12px;justify-content:flex-end}`, "",{"version":3,"sources":["webpack://./src/app/features/review/components/approve/approveModal/ApproveModal.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,QAAA,CACA,wBAAA","sourcesContent":[".actions {\n  display: flex;\n  gap: 12px;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `HqWpx`
};
export default ___CSS_LOADER_EXPORT___;
