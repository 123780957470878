// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H1W1y::part(button){min-width:max-content}`, "",{"version":3,"sources":["webpack://./src/app/features/export/components/Export.module.scss"],"names":[],"mappings":"AAAA,qBACE,qBAAA","sourcesContent":[".btnExport::part(button) {\n  min-width: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnExport": `H1W1y`
};
export default ___CSS_LOADER_EXPORT___;
