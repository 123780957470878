import { AxiosError } from 'axios'
import HTTP_STATUS_CODES from 'http-status-codes'
import { TFunction } from 'i18next'
import _ from 'lodash'

import ReviewHelper from 'app/features/review/services/ReviewService'
import IOption from 'interfaces/common/IOption'
import IProject from 'interfaces/project/IProject'
import IProjectQuestionnaire from 'interfaces/projectQuestionnaire/IProjectQuestionnaire'
import IRTQuestionnaireReviewer from 'interfaces/review/returnTypes/IRTQuestionnaireReviewer'
import SubmitService from 'services/form/SubmitService'
import PermissionService from 'services/permission/PermissionService'
import ProjectService from 'services/project/ProjectService'
import QuestionnaireService from 'services/questionnaire/QuestionnaireService'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import { showErrorAlert } from 'store/reducers/alertSlice'
import {
  setAppUnauthorized,
  setError,
  setInitLoading,
  setLoading,
  setQuestionnaireUnauthorized,
  setState,
} from 'store/reducers/projectBriefSlice'
import { setReviewerInfo, setSelectedReviewers } from 'store/reducers/reviewSlice'

export default class ProjectBriefService {
  /**
   * handle Questionnaire Selection
   * @param {string} accessToken
   * @param {IAppContextState} appContext
   * @param {IOption} option
   * @param {IProjectBriefState} state
   * @param {Function} dispatch
   * @returns {Promise<void>}
   */
  public static readonly handleQuestionnaireSelection = async (
    accessToken: string,
    appContext: IAppContextState,
    option: IOption,
    state: IProjectBriefState,
    dispatch: Function,
  ): Promise<void> => {
    dispatch(setLoading(true))
    const { tenantId, projectId } = appContext

    let parentQuestionnairesList: IOption[] = []

    if (option.parentId) {
      parentQuestionnairesList = await QuestionnaireService.getParentQuestionnaires(
        accessToken,
        tenantId,
        option.parentId,
        projectId,
      )
    }
    dispatch(
      setState({
        ...state,
        questionnaire: {
          ...state.questionnaire,
          questionnaireId: option.id,
          parentQuestionnaireId: option.parentId,
          parentQuestionnaires: parentQuestionnairesList,
          parentQuestionnaireItemId: null,
        },
        loading: false,
      }),
    )
  }

  /**
   * Save/Update project questionnaire
   * @param {string} accessToken
   * @param {IAppContextState} appContext
   * @param {IProjectBriefState} state
   * @param {Function} dispatch
   * @returns {Promise<void>}
   */
  public static readonly onSaveProjectQuestionnaire = async (
    accessToken: string,
    appContext: IAppContextState,
    state: IProjectBriefState,
    dispatch: Function,
  ): Promise<void> => {
    const { questionnaire, isExistingQuestionnaire } = state
    const { questionnaireId, parentQuestionnaireItemId, projectQuestionnaireId } = questionnaire
    dispatch(
      setState({
        ...state,
        openModal: false,
        loading: true,
      }),
    )

    try {
      if (!questionnaireId) {
        return
      }
      const submitService = new SubmitService()
      const { tenantId, projectId, itemId } = appContext
      const projectQId = await submitService.onSaveProjectQuestionnaire(
        accessToken,
        tenantId,
        {
          projectId,
          questionnaireId,
          itemId,
          parentItemId: parentQuestionnaireItemId,
          approval: null,
          appInstanceId: appContext.appInstanceId,
        },
        projectQuestionnaireId,
        isExistingQuestionnaire,
      )

      dispatch(
        setState({
          ...state,
          showQuestionnaireSelection: false,
          loading: false,
          questionnaire: {
            ...state.questionnaire,
            projectQuestionnaireId: projectQId,
          },
          openModal: false,
        }),
      )
    } catch {
      dispatch(setLoading(false))
      dispatch(showErrorAlert())
    }
  }

  /**
   * Handle parent form
   * @param {string} accessToken
   * @param {IAppContextState} appContext
   * @param {IProjectBriefState} state
   * @param {IProjectQuestionnaire} questionnaireData
   * @param {TFunction} t
   * @param {Function} dispatch
   * @returns {Promise<void>}
   */
  public static readonly handleParentForm = async (
    accessToken: string,
    appContext: IAppContextState,
    state: IProjectBriefState,
    questionnaireData: IProjectQuestionnaire,
    t: TFunction,
    dispatch: Function,
  ): Promise<void> => {
    const { parentQuestionnaireId } = state.questionnaire
    const projectQuesId = questionnaireData.id ?? null

    let approvalData = ReviewHelper.getApprovalData(questionnaireData.approval)
    if (projectQuesId && approvalData) {
      const currentReviewer = await ReviewHelper.getCurrentUserReviewInfo(
        accessToken,
        appContext.tenantId,
        projectQuesId,
      )
      approvalData = {
        ...approvalData,
        isReviewer: !!currentReviewer,
      }

      const data: IRTQuestionnaireReviewer = await ReviewHelper.getQuestionnaireReviewers({
        accessToken,
        dispatch,
        projectQuestionnaireId: projectQuesId,
        reviewUpdatedAt: null,
        tenantId: appContext.tenantId,
        t,
      })
      dispatch(setSelectedReviewers(data.reviewers))
      dispatch(setReviewerInfo(currentReviewer))
    }

    if (!questionnaireData.appInstanceId) {
      const submitService = new SubmitService()
      await submitService.onSaveProjectQuestionnaire(
        accessToken,
        appContext.tenantId,
        {
          appInstanceId: appContext.appInstanceId,
          approval: approvalData,
          itemId: appContext.itemId,
          parentItemId: questionnaireData.parentItemId,
          projectId: appContext.projectId,
          questionnaireId: questionnaireData.questionnaireId,
        },
        projectQuesId,
        true,
      )
    }

    if (questionnaireData.parentItemId) {
      dispatch(
        setState({
          ...state,
          showQuestionnaireSelection: false,
          initLoading: false,
          questionnaire: {
            ...state.questionnaire,
            questionnaireId: questionnaireData.questionnaireId,
            parentQuestionnaireId: parentQuestionnaireId,
            projectQuestionnaireId: projectQuesId,
            approval: approvalData,
          },
        }),
      )
      return
    }

    let parentQuestionnairesList: IOption[] = []
    let ques = null
    try {
      ques = await QuestionnaireService.getQuestionnaireById(accessToken, appContext, questionnaireData.questionnaireId)
    } catch (err) {
      const error: AxiosError = err as AxiosError
      dispatch(setInitLoading(false))
      const statusCode = error.response?.status
      if (_.isEqual(statusCode, HTTP_STATUS_CODES.FORBIDDEN)) {
        const responseData: any = error.response?.data
        dispatch(
          setQuestionnaireUnauthorized(
            t('project_brief.unauthorized_questionnaire', { message: responseData.detail.metadata.name }),
          ),
        )
        return
      }
      if (!_.isEqual(statusCode, HTTP_STATUS_CODES.NOT_FOUND)) {
        dispatch(setError(true))
        return
      }
    }

    if (!ques) return

    if (_.isNull(ques.parentQuestionnaireId)) {
      dispatch(
        setState({
          ...state,
          questionnaire: {
            ...state.questionnaire,
            questionnaireId: questionnaireData.questionnaireId,
            parentQuestionnaireId: parentQuestionnaireId,
            projectQuestionnaireId: projectQuesId,
            approval: approvalData,
          },
          showQuestionnaireSelection: false,
          initLoading: false,
        }),
      )
      return
    }
    if (ques.parentQuestionnaireId) {
      const { tenantId, projectId } = appContext
      parentQuestionnairesList = await QuestionnaireService.getParentQuestionnaires(
        accessToken,
        tenantId,
        ques.parentQuestionnaireId || '',
        projectId,
      )
    }

    let showQuesSelection: boolean =
      (!_.isEmpty(parentQuestionnairesList) && _.isNull(questionnaireData.parentItemId)) || _.isNull(projectQuesId)

    dispatch(
      setState({
        ...state,
        questionnaire: {
          ...state.questionnaire,
          questionnaireId: questionnaireData.questionnaireId,
          parentQuestionnaireId: ques.parentQuestionnaireId,
          parentQuestionnaires: parentQuestionnairesList,
          parentQuestionnaireItemId: questionnaireData.parentItemId,
          approval: approvalData,
          questionnaires: [
            {
              id: questionnaireData.questionnaireId,
              label: ques.name,
            },
          ],
          projectQuestionnaireId: projectQuesId,
        },
        initLoading: false,
        showQuestionnaireSelection: showQuesSelection,
        isExistingQuestionnaire: true,
        disableFormSelection: true,
      }),
    )
  }

  /**
   * Load initial data
   * @param {string} accessToken
   * @param {IProjectBriefState} state
   * @param {IAppContextState} appContext
   * @param {TFunction} t
   * @param {Function} dispatch
   * @returns {Promise<void>}
   */
  public static readonly initLoad = async (
    accessToken: string,
    state: IProjectBriefState,
    appContext: IAppContextState,
    t: TFunction,
    dispatch: Function,
  ): Promise<void> => {
    try {
      const { projectId, tenantId, userEmail } = appContext
      const projectInfo: IProject | null = await ProjectService.getProjectInfo(
        accessToken,
        projectId,
        tenantId,
        userEmail,
      )
      const hasAppEditorPermission = PermissionService.hasAppEditor(appContext.permissions) && !_.isNull(projectInfo)
      let selectedQuestionnaire: IProjectQuestionnaire | null = null
      try {
        selectedQuestionnaire = await QuestionnaireService.getSelectedQuestionnaire(accessToken, appContext)
      } catch (err) {
        const error = err as AxiosError
        if (_.isEqual(error.response?.status, HTTP_STATUS_CODES.FORBIDDEN)) {
          dispatch(setAppUnauthorized(true))
          return
        }
      }

      if (_.isNull(selectedQuestionnaire)) {
        const questionnairesList = await QuestionnaireService.getQuestionnaires(accessToken, appContext)
        dispatch(
          setState({
            ...state,
            questionnaire: {
              ...state.questionnaire,
              questionnaires: questionnairesList,
            },
            showQuestionnaireSelection: true,
            disableFormSelection: !hasAppEditorPermission,
            initLoading: false,
          }),
        )
        return
      }
      this.handleParentForm(accessToken, appContext, state, selectedQuestionnaire, t, dispatch)
    } catch {
      dispatch(
        setState({
          ...state,
          error: {
            ...state.error,
            serverError: true,
          },
          initLoading: false,
        }),
      )
    }
  }
}
