// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ka2Ef{display:flex;align-items:center;justify-content:center;z-index:9999;position:fixed;top:0;left:0;bottom:0;right:0}.Ka2Ef .v7fNi{z-index:9999}.Ka2Ef .w9OZ9{position:fixed;top:0;left:0;bottom:0;right:0;z-index:9998;background:var(--wpp-grey-color-200);opacity:.5}`, "",{"version":3,"sources":["webpack://./src/app/components/common/spinner/Spinner.module.scss"],"names":[],"mappings":"AAQA,OACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CAXA,cAAA,CACA,KAAA,CACA,MAAA,CACA,QAAA,CACA,OAAA,CASA,cACE,YAAA,CAEF,cAhBA,cAAA,CACA,KAAA,CACA,MAAA,CACA,QAAA,CACA,OAAA,CAcE,YAAA,CACA,oCAAA,CACA,UAAA","sourcesContent":["@mixin position {\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n}\n\n.container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 9999;\n  @include position;\n  .spinner {\n    z-index: 9999;\n  }\n  .overlay {\n    @include position;\n    z-index: 9998;\n    background: var(--wpp-grey-color-200);\n    opacity: 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Ka2Ef`,
	"spinner": `v7fNi`,
	"overlay": `w9OZ9`
};
export default ___CSS_LOADER_EXPORT___;
