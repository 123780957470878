import React from 'react'

import styles from 'app/components/categorySection/formBuilder/formField/actions/Style.module.scss'
import { WppIconTrash } from 'app/components/common'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'

interface IAddFormFieldProps {
  field: IField
  handleChange: (event: IFieldChangeEvent) => void
  repeatableFieldId?: string
}

/**
 * Remove field component
 * Action to remove repeatable field
 * @param {object} props Component props
 * @param {IField} props.field
 * @param {(event: IFieldChangeEvent) => void} props.handleChange handle input field change event
 * @param {string} props.repeatableFieldId
 */
const RemoveFormField: React.FC<IAddFormFieldProps> = ({
  field,
  handleChange,
  repeatableFieldId,
}: IAddFormFieldProps): React.ReactElement => {
  return (
    <WppIconTrash
      data-testid="remove-form-field"
      onClick={() => {
        if (repeatableFieldId) {
          handleChange({
            field,
            value: field.value,
            repeatableEventType: 'remove',
            repeatableFieldId,
          })
        }
      }}
      className={styles.btnRemove}
    />
  )
}

export default RemoveFormField
