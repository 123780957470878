import _ from 'lodash'
import React, { Fragment, useCallback, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

import styles from 'app/components/common/table/tableBody/TableBody.module.scss'
import TableLoader from 'app/components/common/table/tableLoader'
import ITable from 'interfaces/table/ITable'
import ITableHeader from 'interfaces/table/ITableHeader'
import ITableRow from 'interfaces/table/ITableRow'

interface ITableBodyProps {
  table: ITable
  loadMore?: Function
  infiniteLoading?: boolean
}

/**
 * Table body component
 * @param {object} props
 * @param {ITable} props.table
 * @param {Function} props.loadMore
 * @param {boolean} props.infiniteLoading
 */
const TableBody: React.FC<ITableBodyProps> = ({
  table,
  infiniteLoading,
  loadMore,
}: ITableBodyProps): React.ReactElement => {
  const { colDefs, loading, rowData, paginator, hasMore } = table
  const observer = useRef<IntersectionObserver>()

  const lastElementRef = useCallback(
    (node: HTMLTableRowElement) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !loading && loadMore && hasMore && infiniteLoading) {
          loadMore()
        }
      })

      if (node) observer.current.observe(node)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMore, loading, infiniteLoading],
  )

  return (
    <tbody>
      {rowData.map((data: ITableRow, index: number) => {
        return (
          <tr
            key={uuidv4()}
            className={styles.tableRow}
            ref={_.isEqual(rowData.length, index + 1) && infiniteLoading ? lastElementRef : null}
          >
            {colDefs.map((col: ITableHeader) => {
              if (col.hidden) return <Fragment key={col.id} />
              return (
                <td key={col.id} className={styles.tableCell}>
                  {data[col.id].component ?? (
                    <span
                      className={styles.text}
                      dangerouslySetInnerHTML={{
                        __html: _.toString(data[col.id].text),
                      }}
                    />
                  )}
                </td>
              )
            })}
          </tr>
        )
      })}
      {loading && <TableLoader colDefs={colDefs} itemsPerPage={paginator.itemsPerPage} />}
    </tbody>
  )
}

export default TableBody
