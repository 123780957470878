import _ from 'lodash'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { WppButton, WppSideModal, WppTextareaInput } from 'app/components/common'
import styles from 'app/features/review/components/reject/rejectModal/RejectModal.module.scss'
import { TextareaInputChangeEventDetail, WppTextareaInputCustomEvent } from 'services/types'
import { AppDispatch, RootState } from 'store'
import IReviewState from 'store/interfaces/IReviewState'
import { setRejectModal } from 'store/reducers/reviewSlice'

interface IRejectModalProps {
  onReject: (reason: string) => void
}

/**
 * Reject Modal component
 * Shows reject modal the reviewer
 * @param {object} props
 * @param {(reason: string) => void} props.onReject
 */
const RejectModal: React.FC<IRejectModalProps> = ({ onReject }: IRejectModalProps): React.ReactElement => {
  const { t } = useTranslation()
  const [reason, setReason] = useState<string>('')
  const reviewState = useSelector<RootState, IReviewState>((state: RootState) => state.reviewState)
  const dispatch = useDispatch<AppDispatch>()

  return (
    <>
      {createPortal(
        <WppSideModal
          size="m"
          open={reviewState.isRejectModalOpen}
          data-testid="rejection-side-modal"
          onWppSideModalClose={event => {
            if (_.isEqual(event.detail.reason, 'escapePress')) return
            dispatch(setRejectModal(false))
          }}
          onWppSideModalOpen={() => dispatch(setRejectModal(true))}
          disableOutsideClick={true}
        >
          <h3 slot="header">
            <Trans>review.reject_modal.title</Trans>
          </h3>
          <p slot="body">
            <WppTextareaInput
              name="reason-for-rejection"
              placeholder={t('review.reject_modal.reject_field_placeholder')}
              value={reason}
              charactersLimit={1000}
              id="reason-for-rejection"
              warningThreshold={900}
              data-testid="reason-for-rejection"
              onWppChange={(event: WppTextareaInputCustomEvent<TextareaInputChangeEventDetail>) => {
                setReason(event.detail.value ?? '')
              }}
            />
          </p>
          <div slot="actions" className={styles.actions}>
            <WppButton data-testid="btn-cancel" variant="secondary" onClick={() => dispatch(setRejectModal(false))}>
              <Trans>app.button.cancel</Trans>
            </WppButton>
            <WppButton
              data-testid="btn-reject"
              onClick={() => {
                onReject(reason)
              }}
              disabled={!reason || _.gt(reason.length, 1000)}
            >
              <Trans>app.button.reject_form</Trans>
            </WppButton>
          </div>
        </WppSideModal>,
        document.body,
      )}
    </>
  )
}

export default RejectModal
