import { WppInputCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import _ from 'lodash'
import React from 'react'

import styles from 'app/components/categorySection/CategorySection.module.scss'
import { WppInput } from 'app/components/common'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import { InputChangeEventDetail } from 'services/types'

interface IFormFieldNumberProps {
  field: IField
  handleChange: (event: IFieldChangeEvent) => void
  id?: string
}

/**
 * Create number input field
 * @param {object} props
 * @param {IField} props.field
 * @param {IField} props.id
 * @param {(event: IFieldChangeEvent) => void} props.handleChange
 */
const FormFieldTextInput: React.FC<IFormFieldNumberProps> = ({
  field,
  handleChange,
  id,
}: IFormFieldNumberProps): React.ReactElement => {
  const { disabled, messageType, errorMessage, fieldConfig, value } = field
  const { placeholder } = fieldConfig

  return (
    <WppInput
      placeholder={placeholder}
      value={value}
      onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => {
        handleChange({
          field,
          value: event.detail.value,
        })
      }}
      type="number"
      id={id ?? `numberInput-${field.id}`}
      disabled={disabled}
      messageType={messageType}
      message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
      className={_.isEqual(messageType, 'error') ? '' : styles.customStyleDefaultMessage}
    />
  )
}

export default FormFieldTextInput
