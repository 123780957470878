import _ from 'lodash'

import { ORDER_BY, FIELD_TYPES } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import IOption from 'interfaces/common/IOption'
import IAiAssistantTool from 'interfaces/field/IAiAssistantTool'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import IRepeatableField from 'interfaces/field/repeatable/IRepeatableField'
import IResponseApp from 'interfaces/field/response/IResponseApp'
import IResponseCategory from 'interfaces/field/response/IResponseCategory'
import IResponseField from 'interfaces/field/response/IResponseField'
import IFileConfig from 'interfaces/file/IFileConfig'
import IForm from 'interfaces/form/IForm'
import CommonService from 'services/CommonService'
import FormValidationService from 'services/form/FormValidationService'
import FieldCommentService from 'services/formField/FieldCommentService'
import FormFieldService from 'services/formField/FormFieldService'
import FormFieldValidationService from 'services/formField/FormFieldValidationService'
import PermissionService from 'services/permission/PermissionService'

export default class FormService {
  private readonly formFieldService: FormFieldService
  private readonly formValidationService: FormValidationService
  private readonly formFieldValidationService: FormFieldValidationService
  private readonly commonService: CommonService

  /**
   *  Creates an instance of FormHelper
   */
  constructor() {
    this.formFieldService = new FormFieldService()
    this.formValidationService = new FormValidationService()
    this.formFieldValidationService = new FormFieldValidationService()
    this.commonService = new CommonService()
  }

  /**
   * Sort fields, forms, and categories order
   * @param {T[]} data
   * @returns {T[]}
   */
  public static getOrderedData<T>(data: T[], orderKey: string): T[] {
    return _.orderBy(data, [orderKey], ORDER_BY.ASC)
  }

  /**
   * get default field value
   * @param {IResponseField} field
   * @returns {any}
   */
  getDefaultFieldValue = (field: IResponseField) => {
    const defaultFieldValue = !field.defaultValue ? field.defaultValue : field.defaultValue.value
    if (_.gt(field.maxRepeatableAmount, 0)) {
      return defaultFieldValue
    }
    let FieldValue = this.formFieldValidationService.getValidValue(field, defaultFieldValue)
    return FieldValue
  }

  /**
   * get default field value for repeatable field
   * @param {IResponseField} field
   * @returns {IRepeatableField[]}
   */
  getRepeatableFieldValue = (field: IResponseField): IRepeatableField[] => {
    if (_.gt(field.maxRepeatableAmount, 0)) {
      return this.formFieldValidationService.getValidRepeatableField(field, this.getDefaultFieldValue(field))
    }
    return []
  }

  /**
   * Build field data for the app
   * @param {string} projectQuestionnaireId
   * @param {string} categoryId
   * @param {IResponseField} field
   * @param {string} accessToken
   * @param {string} tenantId
   * @param {string} projectId
   * @param {boolean} isAppEditor
   * @returns {Promise<IField>}
   */
  async buildField(
    projectQuestionnaireId: string,
    categoryId: string,
    field: IResponseField,
    accessToken: string,
    tenantId: string,
    projectId: string,
    isAppEditor: boolean,
  ): Promise<IField> {
    const Children: IResponseField[] = _.isArray(field.children) ? field.children : []

    let fileConfig: IFileConfig = {}
    if (_.isEqual(field.fieldType, FIELD_TYPES.FILE_UPLOAD) && field.config?.file) {
      const { accept, max_size, template_title, template_url } = field.config.file
      fileConfig = {
        maxSize: max_size,
        accept: accept,
        templateTitle: template_title,
        templateUrl: template_url,
      }
    }

    let autoComplete: any = {}

    if (
      _.isEqual(field.fieldType, FIELD_TYPES.AUTOCOMPLETE) &&
      field.config &&
      !_.isUndefined(field.config.autocomplete)
    ) {
      const { items_per_page, keys, order_by } = field.config.autocomplete

      const { page, id, label, sub_label, avatar_url, display_label } = keys
      autoComplete = {
        keys: {
          page,
          id: id,
          label: label,
          subLabel: sub_label,
          avatarUrl: avatar_url,
          itemsPerPage: keys.items_per_page,
          displayLabel: display_label,
        },
        itemsPerPage: items_per_page,
        orderBy: order_by,
      }
    }

    const FieldValue = this.getDefaultFieldValue(field)

    let visibleOnValue = field.config ? field.config?.visible_on_value : []
    visibleOnValue = !_.isArray(visibleOnValue) ? [] : visibleOnValue.map((value: string) => _.toString(value))

    let children = []

    for (let child of Children) {
      children.push(
        await this.buildField(projectQuestionnaireId, categoryId, child, accessToken, tenantId, projectId, isAppEditor),
      )
    }

    const Options: IOption[] = await this.commonService.getSelectOptions(field, accessToken, tenantId)

    const aiAssistantTools = !_.isArray(field.aiAssistantTools)
      ? []
      : field.aiAssistantTools.map((assistantTool: IAiAssistantTool) => ({
          ...assistantTool,
          id: `${assistantTool.name}-${field.id}`,
        }))

    return this.formFieldValidationService.validateFieldConfiguration({
      id: field.id,
      formFieldId: field.formFieldId,
      type: field.fieldType,
      fieldComment: {
        comments: [],
        startDate: null,
        page: 1,
        uniqueUsers: [],
        hasMore: true,
      },
      value: FieldValue,
      isCompleted: true,
      maxRepeatableAmount: field.maxRepeatableAmount,
      initialValue: _.cloneDeep(FieldValue),
      repeatableFields: this.getRepeatableFieldValue(field),
      displayOrder: field.displayOrder,
      isHidden: false,
      isRequired: field.isRequired,
      aiAssistantTools,
      fieldConfig: {
        projectQuestionnaireId,
        categoryId,
        text: field.label,
        description: field.description,
        help: !field.help ? '' : field.help.text,
        tooltip: field.tooltip,
        setAsReviewers: !!field.config?.set_as_reviewers,
        accessToken,
        tenantId,
        projectId,
        apiUrl: field.apiUrl,
        placeholder: field.placeholder,
        options: Options,
        orientation: field.orientation,
        charactersLimit: !_.isNull(field.config) ? field.config?.character_limit : undefined,
        multi: !!field.config?.multi,
        file: fileConfig,
        helpModal: !_.isNull(field.help) ? field.help.help_model : undefined,
        autoComplete,
        valueAmount: field.config?.value_amount,
      },
      disabled: !isAppEditor,
      touched: false,
      isValid: true,
      errorMessage: '',
      visibleOn: field.visibleOn ?? null,
      visibleOnValue,
      children: FormService.getOrderedData<IField>(children, 'displayOrder'),
    })
  }

  /**
   * Default field configuration
   * @param {string} projectQuestionnaireId
   * @param {string} categoryId
   * @param {IResponseField} field
   * @param {boolean} IsAppEditor
   * @param {string} accessToken
   * @param {string} tenantId
   * @param {string} projectId
   * @returns {Promise<IField>}
   */
  async defaultFieldConfiguration(
    projectQuestionnaireId: string,
    categoryId: string,
    field: IResponseField,
    IsAppEditor: boolean,
    accessToken: string,
    tenantId: string,
    projectId: string,
  ): Promise<IField> {
    let updatedField: IField = await this.buildField(
      projectQuestionnaireId,
      categoryId,
      field,
      accessToken,
      tenantId,
      projectId,
      IsAppEditor,
    )

    return this.formFieldValidationService.validateFieldConfiguration({
      ...updatedField,
      isCompleted: !FormFieldValidationService.isEmptyField(updatedField) && updatedField.isValid,
    })
  }

  /**
   * Updated Category
   * @returns {Promise<ICategory>}
   */
  async updateCategory({
    accessToken,
    categories,
    category,
    categoryIndex,
    forms,
    projectQuestionnaireId,
    showAlert,
    tenantId,
  }: {
    accessToken: string
    tenantId: string
    projectQuestionnaireId: string
    forms: IForm[]
    categories: IResponseCategory[]
    category: IResponseCategory
    showAlert: Function
    categoryIndex: number
  }): Promise<ICategory> {
    let updatedCategory: ICategory = {
      ...category,
      name: category.name,
      isCommentLoaded: false,
      description: category.description,
      isValid: true,
      dirty: false,
      forms: FormService.getOrderedData<IForm>(forms, 'displayOrder'),
      categoryIndex,
      touched: false,
    }

    if (_.isEqual(_.first(categories)?.id, category.id)) {
      updatedCategory = await FieldCommentService.getCommentsByCategoryId(
        accessToken,
        tenantId,
        projectQuestionnaireId,
        updatedCategory,
        showAlert,
      )
    }

    return updatedCategory
  }

  /**
   * Get application configuration
   * @returns {Promise<IApp>}
   */
  async getApp({
    app,
    accessToken,
    isAppEditable,
    isProjectMember,
    permissions,
    projectId,
    projectQuestionnaireId,
    showAlert,
    tenantId,
  }: {
    app: IResponseApp
    permissions: string[]
    accessToken: string
    tenantId: string
    projectId: string
    projectQuestionnaireId: string
    isAppEditable: boolean
    isProjectMember: boolean
    showAlert: Function
  }): Promise<IApp> {
    const IsAppEditor = PermissionService.hasAppEditor(permissions) && isAppEditable
    let updatedCategories = []

    for (let category of app.categories) {
      let forms: IForm[] = []
      for (let form of category.forms) {
        let fields: IField[] = []
        for (let field of form.fields) {
          fields.push(
            await this.defaultFieldConfiguration(
              projectQuestionnaireId,
              category.id,
              field,
              IsAppEditor && isProjectMember,
              accessToken,
              tenantId,
              projectId,
            ),
          )
        }

        forms.push({
          ...form,
          helpModal: !_.isNull(form.help) ? form.help.help_model : undefined,
          description: form.description,
          help: !form.help ? '' : form.help.text,
          tooltip: form.tooltip ?? '',
          fields: FormService.getOrderedData<IField>(fields, 'displayOrder'),
        })
      }
      const updatedCategory = await this.updateCategory({
        accessToken,
        tenantId,
        projectQuestionnaireId,
        forms,
        categories: app.categories,
        category,
        showAlert,
        categoryIndex: 0,
      })

      updatedCategories.push(updatedCategory)
    }

    updatedCategories = this.updateDependentFields(
      FormService.getOrderedData<ICategory>(updatedCategories, 'displayOrder'),
    )

    updatedCategories = _.orderBy(updatedCategories, ['order'], 'asc').map((category: ICategory, index: number) => ({
      ...category,
      categoryIndex: index + 1,
      touched: false,
    }))

    let updatedApp: IApp = {
      appName: app.name,
      isValid: true,
      isAppEditor: IsAppEditor,
      completionRate: {
        completed: 0,
        total: 0,
        percentage: 0,
      },
      isTouched: false,
      categories: updatedCategories,
      isProjectMember,
      currentCategoryId: updatedCategories[0].id,
    }

    return {
      ...updatedApp,
      completionRate: this.formFieldService.getCompletionRate(updatedApp),
    }
  }

  /**
   * Update field in the app
   * @param {IApp} app
   * @param {IFieldChangeEvent} event
   * @returns {IApp}
   */
  updateApp(app: IApp, event: IFieldChangeEvent): IApp {
    let categories: ICategory[] = this.updateForms(app.categories, event)
    categories = this.formValidationService.validateDependentField(categories, event)
    categories = this.formValidationService.validateCategories(categories)
    const updatedApp: IApp = this.formValidationService.validateForms(app, categories)
    const App: IApp = {
      ...updatedApp,
      isTouched: true,
    }

    return {
      ...App,
      completionRate: this.formFieldService.getCompletionRate(App),
    }
  }

  /**
   * Update forms
   * @param {ICategory[]} categories
   * @param {IFieldChangeEvent} event
   * @returns {ICategory[]}
   */
  updateForms(categories: ICategory[], event: IFieldChangeEvent): ICategory[] {
    return this.updateDependentFields(
      categories.map((category: ICategory) => ({
        ...category,
        dirty: _.isEqual(event.categoryId, category.id),
        forms: category.forms.map((form: IForm) => ({
          ...form,
          fields: form.fields.map((field: IField) => this.formFieldService.updateField(field, event)),
        })),
      })),
    )
  }

  /**
   * Show/hide fields based on parent field
   * @param {ICategory[]} categories
   * @param {IField} field
   * @returns {IField}
   */
  updateDependentField(categories: ICategory[], field: IField): IField {
    const VisibleOn = field.visibleOn ?? null
    let children: IField[] = []
    field.children.forEach((childField: IField) => {
      children.push(this.updateDependentField(categories, childField))
    })
    if (!_.isNull(VisibleOn) && !_.isNull(field.visibleOnValue)) {
      const Field: IField | null = this.formFieldService.findFieldByFormFieldId(categories, VisibleOn)
      const isHidden = !Field ? false : this.formFieldService.isFieldHidden(Field, field)
      return {
        ...field,
        children,
        isHidden,
        isCompleted: field.isValid && !FormFieldValidationService.isEmptyField(field),
      }
    }
    return {
      ...field,
      children,
    }
  }

  /**
   * Update dependent fields
   * @param {ICategory[]} categories
   * @returns {ICategory[]}
   */
  updateDependentFields(categories: ICategory[]): ICategory[] {
    return categories.map((category: ICategory) => ({
      ...category,
      forms: category.forms.map((form: IForm) => ({
        ...form,
        fields: form.fields.map((field: IField) => this.updateDependentField(categories, field)),
      })),
    }))
  }

  /**
   * Update category status if any field has been updated
   * @param {IApp} app
   * @param {string} categoryId
   * @param {boolean} dirty
   * @returns {IApp}
   */
  updateCategoryStatus(app: IApp, categoryId: string, dirty: boolean): IApp {
    return {
      ...app,
      categories: app.categories.map((category: ICategory) => {
        const selectedCategory = _.isEqual(categoryId, category.id)
        return {
          ...category,
          dirty: selectedCategory ? dirty : category.dirty,
          touched: selectedCategory ? true : category.touched,
        }
      }),
    }
  }
  /**
   * Update fields
   * @param {IForm} form
   * @param {boolean} isAppEditable
   * @returns {IField[]}
   */
  updateFields(form: IForm, isAppEditable: boolean): IField[] {
    return form.fields.map((field: IField) => ({
      ...field,
      disabled: !isAppEditable,
      children: field.children.map((field: IField) => ({ ...field, disabled: !isAppEditable })),
    }))
  }

  /**
   * Update app editable status
   * @param {IApp} app
   * @param {boolean} isAppEditable
   * @returns {IApp}
   */
  updateEditableAppStatus(app: IApp, isAppEditable: boolean = true): IApp {
    return {
      ...app,
      isAppEditor: isAppEditable,
      categories: app.categories.map((category: ICategory) => ({
        ...category,
        forms: category.forms.map((form: IForm) => ({
          ...form,
          fields: this.updateFields(form, isAppEditable),
        })),
      })),
    }
  }

  /**
   * Pre-save check category for file upload
   * @param {ICategory} category
   * @param {string} accessToken
   * @param {string} tenantId
   * @param {string} tenantUrl
   * @param {string} projectId
   * @returns {Promise<ICategory>}
   */
  async preSaveCheck(
    category: ICategory,
    accessToken: string,
    tenantId: string,
    tenantUrl: string,
    projectId: string,
  ): Promise<ICategory> {
    let forms: IForm[] = []
    for (let form of category.forms) {
      const Fields: IField[] = []
      for (let field of form.fields) {
        const Field: IField = await this.formFieldService.preSaveFieldCheck(
          field,
          accessToken,
          tenantId,
          tenantUrl,
          projectId,
        )
        const Children: IField[] = []
        for (let children of Field.children) {
          const ChildField: IField = await this.formFieldService.preSaveFieldCheck(
            children,
            accessToken,
            tenantId,
            tenantUrl,
            projectId,
          )
          Children.push(ChildField)
        }
        Field.children = Children
        Fields.push(Field)
      }

      forms.push({
        ...form,
        fields: Fields,
      })
    }
    return {
      ...category,
      dirty: false,
      forms,
    }
  }

  /**
   * Update field initial value for the category
   * @param {ICategory} category
   * @param {ICategory}
   */
  public static readonly updateCategoryFieldInitialValue = (category: ICategory): ICategory => {
    return {
      ...category,
      forms: category.forms.map((form: IForm) => ({
        ...form,
        fields: form.fields.map((field: IField) => FormFieldService.updateFieldInitialValue(field)),
      })),
    }
  }
}
