// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dff4I::part(item){min-width:var(--toast-width);width:auto}`, "",{"version":3,"sources":["webpack://./src/app/components/common/toast/Toast.module.scss"],"names":[],"mappings":"AACE,mBACE,4BAAA,CACA,UAAA","sourcesContent":[".toastContainer {\n  &::part(item) {\n    min-width: var(--toast-width);\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toastContainer": `dff4I`
};
export default ___CSS_LOADER_EXPORT___;
