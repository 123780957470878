import { MentionData } from '@draft-js-plugins/mention'
import { useOs } from '@wppopen/react'
import { AxiosError } from 'axios'
import HTTP_STATUS_CODES from 'http-status-codes'
import _ from 'lodash'
import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import styles from 'app/components/categorySection/formField/formFieldComment/FormFieldComment.module.scss'
import FormFieldCommentDetail from 'app/components/categorySection/formField/formFieldComment/formFieldCommentDetail'
import FormFieldCommentEditor from 'app/components/categorySection/formField/formFieldComment/formFieldCommentEditor'
import FormFieldCommentIcon from 'app/components/categorySection/formField/formFieldComment/FormFieldCommentIcon'
import { WppPopover, WppProgressIndicator } from 'app/components/common'
import { COMMENTS_PER_PAGE } from 'config/constants'
import { COMMENT_ACTION_TYPES, QUESTIONNAIRE_STATUS } from 'config/enums'
import ICategory from 'interfaces/category/ICategory'
import IComment from 'interfaces/field/fieldComment/IComment'
import IField from 'interfaces/field/IField'
import IProjectMember from 'interfaces/project/IProjectMember'
import FieldCommentService from 'services/formField/FieldCommentService'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import IReviewState from 'store/interfaces/IReviewState'
import { setErrorAlert } from 'store/reducers/alertSlice'
import { setState } from 'store/reducers/questionnaireSlice'

interface IFormFieldCommentProps {
  field: IField
}

/**
 * Form field comment component
 * @param {object} props
 * @param {IField} props.field
 */
const FormFieldComment: React.FC<IFormFieldCommentProps> = ({ field }: IFormFieldCommentProps): React.ReactElement => {
  const { osApi } = useOs()
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const { reviewers } = useSelector<RootState, IReviewState>((state: RootState) => state.reviewState)
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const projectBriefState = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)
  const { app } = questionnaireState

  const [loading, setLoading] = useState<boolean>(false)
  const [activeAction, setActiveAction] = useState<boolean>(false)

  const popoverRef: React.RefObject<HTMLWppPopoverElement> = useRef<HTMLWppPopoverElement>(null)
  const projectQuestionnaireId: string = _.toString(projectBriefState.questionnaire.projectQuestionnaireId)

  const mentionOptions: MentionData[] = useMemo(
    () =>
      reviewers.map((reviewer: IProjectMember) => ({
        name: `${reviewer.firstname} ${reviewer.lastname}`,
        id: reviewer.email,
        link: reviewer.avatarUrl,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const handleCommentAction = async (
    field: IField,
    actionType: COMMENT_ACTION_TYPES,
    cb: Function,
    config?: Object,
    commentParam?: IComment,
    value: string = '',
    mentions: string[] = [],
  ) => {
    const category: ICategory | undefined = _.find(questionnaireState.app?.categories, {
      id: field.fieldConfig.categoryId,
    })
    if (!category) return

    try {
      await FieldCommentService.handleCommentAction({
        accessToken: osApi.getAccessToken(),
        category,
        field,
        projectQuestionnaireId,
        appContext,
        questionnaireState,
        dispatch,
        value,
        type: actionType,
        updatedComment: commentParam,
        mentions,
        config,
      })
      cb()
    } catch (error) {
      dispatch(setState({ ...questionnaireState, loading: false }))
      cb(error)
      const err = error as AxiosError
      let errorMessage: string = t('questionnaire.error.save')

      if (_.isEqual(actionType, COMMENT_ACTION_TYPES.ADD)) {
        errorMessage = t('comment.error.add_comment')
      } else if (_.isEqual(actionType, COMMENT_ACTION_TYPES.EDIT)) {
        errorMessage = t('comment.error.edit_comment')
      } else if (_.isEqual(actionType, COMMENT_ACTION_TYPES.DELETE)) {
        errorMessage = t('comment.error.delete_comment')
      }

      if (_.isEqual(err.response?.status, HTTP_STATUS_CODES.FORBIDDEN)) {
        errorMessage = t('questionnaire.error.unauthorized_save')
      }

      dispatch(setErrorAlert(errorMessage))
    }
  }

  const onEditComment = (commentParam: IComment, config: Object, value: string, mentions: string[], cb: Function) => {
    handleCommentAction(field, COMMENT_ACTION_TYPES.EDIT, cb, config, commentParam, value, mentions)
  }

  const onDeleteComment = (commentParam: IComment, cb: Function) => {
    handleCommentAction(field, COMMENT_ACTION_TYPES.DELETE, cb, undefined, commentParam)
  }

  const loadMoreComment = async () => {
    const { page, startDate } = field.fieldComment
    const updatedStartDate = _.isNull(startDate) ? new Date().toISOString() : startDate

    const newComments: IComment[] = await FieldCommentService.loadMoreComment(field, page, updatedStartDate)

    const hasMore = !_.isEmpty(newComments) && _.isEqual(newComments.length, COMMENTS_PER_PAGE)
    FieldCommentService.setCommentsForFormField(
      app,
      field,
      {
        ...field.fieldComment,
        comments: _.uniqBy(_.concat(field.fieldComment.comments, newComments), 'id'),
        page: page + 1,
        hasMore,
        startDate: updatedStartDate,
      },
      dispatch,
    )
  }

  const isCommentAllowed =
    !_.isEqual(projectBriefState.questionnaire.approval?.status, QUESTIONNAIRE_STATUS.SUCCEEDED) &&
    !!app?.isProjectMember

  return (
    <div className={styles.rootContainer}>
      <WppPopover
        ref={popoverRef}
        config={{
          onHide: () => {
            if (activeAction) return false
          },
          popperOptions: {
            placement: 'left',
          },
          appendTo: () => document.querySelector('#micro-app') || document.body,
          onShow: () => {
            if (field.fieldComment.hasMore && !_.isEmpty(field.fieldComment.uniqueUsers)) {
              setLoading(true)
              loadMoreComment()
                .then(() => {
                  setLoading(false)
                })
                .catch(() => {
                  setLoading(false)
                  dispatch(setErrorAlert(t('comment.error.fetch_comments')))
                })
            }
          },
        }}
      >
        <FormFieldCommentIcon fieldComment={field.fieldComment} />
        <div className={styles.commentContainer}>
          {loading && <WppProgressIndicator />}
          <FormFieldCommentDetail
            field={field}
            onEdit={onEditComment}
            onDelete={onDeleteComment}
            loadMoreComment={loadMoreComment}
            isCommentAllowed={isCommentAllowed}
            mentionOptions={mentionOptions}
            setActiveAction={setActiveAction}
          />
          {isCommentAllowed && (
            <FormFieldCommentEditor
              field={field}
              handleCommentAction={handleCommentAction}
              mentionOptions={mentionOptions}
              popoverRef={popoverRef}
              setActiveAction={setActiveAction}
            />
          )}
        </div>
      </WppPopover>
    </div>
  )
}

export default FormFieldComment
