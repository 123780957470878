// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.W1968{width:100%;cursor:pointer}.W1968::part(step-bg),.W1968::part(step-wrapper){width:100%}`, "",{"version":3,"sources":["webpack://./src/app/components/stepper/verticalStepper/VerticalStepper.module.scss"],"names":[],"mappings":"AAAA,OAKE,UAAA,CACA,cAAA,CALA,iDAEE,UAAA","sourcesContent":[".categoryStep {\n  &::part(step-bg),\n  &::part(step-wrapper) {\n    width: 100%;\n  }\n  width: 100%;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryStep": `W1968`
};
export default ___CSS_LOADER_EXPORT___;
