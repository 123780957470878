import React from 'react'
import { useSelector } from 'react-redux'

import { WppDivider, WppGrid } from 'app/components/common'
import styles from 'app/components/toolbar/Toolbar.module.scss'
import { RootState } from 'store'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'

interface IToolbarProps {
  children: React.ReactElement
}

/**
 * Application toolbar component
 * @param {object} props
 * @param {React.ReactElement} props.children
 */
const Toolbar: React.FC<IToolbarProps> = ({ children }: IToolbarProps): React.ReactElement => {
  const { app } = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)

  if (!app) return <></>

  return (
    <div className={styles.headerContainer}>
      <WppGrid container>
        <WppGrid item all={24} className={styles.header}>
          {children}
        </WppGrid>
      </WppGrid>
      <WppDivider className={styles.divider} />
    </div>
  )
}

export default Toolbar
