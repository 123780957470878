import _ from 'lodash'
import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

import { NotFoundError } from 'app/components/common/errorComponents'
import styles from 'app/components/common/table/Table.module.scss'
import TableBody from 'app/components/common/table/tableBody'
import TableHeader from 'app/components/common/table/tableHeader'
import { ORDER_BY } from 'config/enums'
import ITable from 'interfaces/table/ITable'

interface ITableProps {
  table: ITable
  onColumnOrderChange: (orderBy: ORDER_BY, orderByColumn: string) => void
  loadMore?: Function
  infiniteLoading?: boolean
  customStyle?: CSSProperties
}

/**
 * Table component
 * @param {object} props
 * @param {ITable} props.table
 * @param {Function} props.onColumnOrderChange
 * @param {Function} props.loadMore
 * @param {boolean} props.infiniteLoading
 * @param {boolean} props.customStyle
 */
const Table: React.FC<ITableProps> = ({
  table,
  onColumnOrderChange,
  loadMore,
  infiniteLoading,
  customStyle,
}: ITableProps): React.ReactElement => {
  const { t } = useTranslation()
  return (
    <div className={styles.tableContainer} style={{ ...customStyle }}>
      <table className={styles.table}>
        <TableHeader table={table} onColumnOrderChange={onColumnOrderChange} />
        <TableBody loadMore={loadMore} infiniteLoading={infiniteLoading} table={table} />
      </table>
      {_.isEmpty(table.rowData) && !table.loading && (
        <NotFoundError title={t('table.not_found.title')} description={t('table.not_found.description')} />
      )}
    </div>
  )
}

export default Table
