import React from 'react'

import { WppStep, WppStepper } from 'app/components/common'
import styles from 'app/components/stepper/verticalStepper/VerticalStepper.module.scss'
import { STEP_ACTIONS } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import { StepChangeEventDetail, WppStepCustomEvent } from 'services/types'

interface IVerticalStepperProps {
  app: IApp
  activeStep: number
  handleStep: (stepNumber: number, stepAction: STEP_ACTIONS, nextCategory: ICategory) => void
}

/**
 * VerticalStepper component
 * Shows category in the left stepper
 * @param {object} props
 * @param {IApp} props.app
 * @param {number} props.activeStep
 * @param {(stepNumber: number, stepAction: STEP_ACTIONS, nextCategory: ICategory) => void} props.handleStep
 */
const VerticalStepper: React.FC<IVerticalStepperProps> = ({
  handleStep,
  app,
  activeStep,
}: IVerticalStepperProps): React.ReactElement => {
  return (
    <WppStepper stepperWidth="100%" activeStep={activeStep}>
      {app.categories.map((category: ICategory) => {
        const completed: boolean = category.isValid && category.touched
        return (
          <WppStep
            key={category.id}
            onWppStepChange={(event: WppStepCustomEvent<StepChangeEventDetail>) => {
              const stepNumber: number = event.detail.index ?? 1
              handleStep(stepNumber, STEP_ACTIONS.STEP, category)
            }}
            data-testid={category.id}
            className={styles.categoryStep}
            completed={completed}
            error={!category.isValid}
          >
            <p slot="label">{category.name}</p>
          </WppStep>
        )
      })}
    </WppStepper>
  )
}

export default VerticalStepper
