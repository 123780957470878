import copy from 'copy-to-clipboard'
import _ from 'lodash'
import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  WppIconButton,
  WppIconCopy,
  WppIconEye,
  WppIconMore,
  WppListItem,
  WppMenuContext,
  WppTypography,
} from 'app/components/common'
import CancelReview from 'app/features/review/components/cancelReview'
import Reviewers from 'app/features/review/components/reviewers'
import { TOAST_MESSAGE_TYPES } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import FormFieldService from 'services/formField/FormFieldService'
import { AppDispatch, RootState } from 'store'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import { showAlert } from 'store/reducers/alertSlice'

const formFieldService = new FormFieldService()

interface IMenuProps {
  app: IApp | null
}

/**
 * Action menu items
 * Shows menu in app header i.e View change log, copy to clipboard action for brief ID and launch URL etc.
 * @param {object} props
 * @param {IApp | null} props.app
 */
const Menu: React.FC<IMenuProps> = ({ app }: IMenuProps): React.ReactElement => {
  const projectBriefState = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const fieldsData = formFieldService.getFormFieldsObject(app!.categories)

  /**
   * Copy selected data to clipboard and shows success alert
   */
  const onCopyToClipboard = useCallback(
    (alertMessage: string, text: string | null): void => {
      copy(text ?? '')
      dispatch(
        showAlert({
          message: alertMessage,
          type: TOAST_MESSAGE_TYPES.SUCCESS,
        }),
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <WppMenuContext>
      <WppIconButton slot="trigger-element">
        <WppIconMore size="s" />
      </WppIconButton>
      <div>
        <Reviewers />
        <CancelReview />
        <WppListItem data-testid="change-log-link" onWppChangeListItem={() => navigate('/change-log')}>
          <p slot="left">
            <WppIconEye />
          </p>
          <p slot="label">
            <WppTypography tag="span" type="s-body">
              <Trans>action.menu.change_log</Trans>
            </WppTypography>
          </p>
        </WppListItem>
        {_.isEqual(app!.appName, 'Idea Brief') && (
          <WppListItem
            data-testid="copy-campaign-details"
            onWppChangeListItem={() =>
              onCopyToClipboard(
                t('action.alert_message.campaign_details'),
                `Brand: Coca-Cola Campaign for FIFA Women's World Cup 2027\nHosting countries: US and Mexico\nInsights: ${fieldsData['the insights']}\nAudiences: Players, National Fans, Superstar Fans, Coerced Companions, Extended Family, Nuclear Family, First Timers, City Citizens, Party Goers, Young Culturalist Couples, Weekend Socialites, Hall Passers, Sports Fans, Patriots\nSuggest ideas for each of the activation types: digital, live, product and retail activations has context menu`,
              )
            }
          >
            <p slot="left">
              <WppIconCopy />
            </p>
            <p slot="label">
              <WppTypography tag="span" type="s-body">
                <Trans>app.button.copy_campaign_details</Trans>
              </WppTypography>
            </p>
          </WppListItem>
        )}
        <WppListItem
          data-testid="copy-brief-launch-url"
          onWppChangeListItem={() =>
            onCopyToClipboard(t('action.alert_message.copy_brief_launch_url'), window.location.href)
          }
        >
          <p slot="left">
            <WppIconCopy />
          </p>
          <p slot="label">
            <WppTypography tag="span" type="s-body">
              <Trans>action.menu.copy_brief_launch_url</Trans>
            </WppTypography>
          </p>
        </WppListItem>
        <WppListItem
          data-testid="copy-brief-id-number"
          onWppChangeListItem={() =>
            onCopyToClipboard(
              t('action.alert_message.copy_brief_id_number'),
              projectBriefState.questionnaire.projectQuestionnaireId,
            )
          }
        >
          <p slot="left">
            <WppIconCopy />
          </p>
          <p slot="label">
            <WppTypography tag="span" type="s-body">
              <Trans>action.menu.copy_brief_id_number</Trans>
            </WppTypography>
          </p>
        </WppListItem>
      </div>
    </WppMenuContext>
  )
}

export default Menu
