// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lIQZs{display:flex;align-items:center;justify-content:space-between}.lIQZs .JqgbZ{display:flex;align-items:center;gap:12px}.XYGn8{display:flex;flex-direction:column;gap:24px}.XYGn8 .Fzb_3{display:flex;flex-direction:column;gap:8px}.XYGn8 .Fzb_3 .j6CaD{width:fit-content}`, "",{"version":3,"sources":["webpack://./src/app/features/changeLog/components/filter/SideModalFilter.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,cACE,YAAA,CACA,kBAAA,CACA,QAAA,CAIJ,OACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,cACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,qBACE,iBAAA","sourcesContent":[".actions {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  .btnActionContainer {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n  }\n}\n\n.sideModalBody {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n\n  .fieldContainer {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n\n    .checkbox {\n      width: fit-content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `lIQZs`,
	"btnActionContainer": `JqgbZ`,
	"sideModalBody": `XYGn8`,
	"fieldContainer": `Fzb_3`,
	"checkbox": `j6CaD`
};
export default ___CSS_LOADER_EXPORT___;
