import _ from 'lodash'
import React from 'react'

import Field from 'app/components/categorySection/formBuilder/formField/Field'
import styles from 'app/components/categorySection/formField/formFieldChildren/FormFieldChildren.module.scss'
import IField from 'interfaces/field/IField'
import CommonService from 'services/CommonService'
import FormFieldService from 'services/formField/FormFieldService'

interface IFormFieldChildrenProps {
  field: IField
  optionValue: any
}

const formFieldService = new FormFieldService()
const commonService = new CommonService()

/**
 * Create  child fields for parent field
 * @param {object} props
 * @param {IField} props.field
 * @param {any} props.optionValue
 */
const FormFieldChildren: React.FC<IFormFieldChildrenProps> = ({
  field,
  optionValue,
}: IFormFieldChildrenProps): React.ReactElement => {
  const { value } = field
  const children: IField[] = formFieldService.getChildren(field, value)

  return (
    <div className={styles.container}>
      {children.map((childField: IField) => {
        if (commonService.findAnyArrayValueInTargetArray(childField.visibleOnValue, [_.toString(optionValue)])) {
          return (
            <div className={styles.marginTop} key={childField.id}>
              <Field field={childField} isChildField />
            </div>
          )
        }
        return <span key={childField.id} />
      })}
    </div>
  )
}

export default FormFieldChildren
