import { Font } from '@react-pdf/renderer'
import _ from 'lodash'
import React from 'react'
import { Trans } from 'react-i18next'

import { WppIconFilePdf, WppListItem, WppTypography } from 'app/components/common'
import { PDF_FONT_FAMILY_NAME, PDF_FONTS_SRC } from 'config/constants'
import { APP_ACTIONS, TOAST_MESSAGE_TYPES } from 'config/enums'

interface IPdfViewerProps {
  handleAppAction: (
    appAction: string,
    callback: (message: TOAST_MESSAGE_TYPES) => void,
    displayMessage?: boolean,
  ) => void
  handlePdfExport: Function
}

Font.register({
  family: PDF_FONT_FAMILY_NAME,
  fonts: PDF_FONTS_SRC,
})

/**
 * PDF Viewer component
 * Shows button for PDF export
 * @param {object} props
 * @param {Function} props.handleAppAction
 * @param {Function} props.handlePdfExport
 */
const PdfViewer: React.FC<IPdfViewerProps> = ({
  handleAppAction,
  handlePdfExport,
}: IPdfViewerProps): React.ReactElement => {
  const handlePdfViewer = () => {
    handleAppAction(APP_ACTIONS.EXPORT, (message: TOAST_MESSAGE_TYPES) => {
      if (!_.isEqual(message, TOAST_MESSAGE_TYPES.ERROR)) handlePdfExport()
    })
  }

  return (
    <WppListItem data-testid="btn-pdf" id="btn-pdf" onWppChangeListItem={handlePdfViewer}>
      <p slot="left">
        <WppIconFilePdf />
      </p>
      <p slot="label">
        <WppTypography tag="span" type="s-body">
          <Trans>action.export.title_pdf_viewer</Trans>
        </WppTypography>
      </p>
    </WppListItem>
  )
}

export default PdfViewer
