import { useOs } from '@wppopen/react'
import { t } from 'i18next'
import { first, isEmpty, isEqual, orderBy } from 'lodash'
import React from 'react'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'

import styles from 'app/components/categorySection/formField/formFieldAssistantTool/FormFieldAssistantTool.module.scss'
import {
  WppAccordion,
  WppActionButton,
  WppButton,
  WppIconStudio,
  WppTooltip,
  WppTypography,
} from 'app/components/common'
import IAiAssistantTool from 'interfaces/field/IAiAssistantTool'
import IField from 'interfaces/field/IField'
import { AppDispatch } from 'store'
import { setErrorAlert } from 'store/reducers/alertSlice'

interface IFormFieldAssistantToolProps {
  field: IField
}

/**
 * Shows Assistant Tools for the field
 * @param {object} props
 * @param {IField} props.field
 */
const FormFieldAssistantTool: React.FC<IFormFieldAssistantToolProps> = ({
  field,
}: IFormFieldAssistantToolProps): React.ReactElement => {
  const { osApi } = useOs()
  const dispatch = useDispatch<AppDispatch>()

  if (!field.aiAssistantTools || isEmpty(field.aiAssistantTools) || field.disabled) return <></>

  const openApp = (assistantTool: IAiAssistantTool) => {
    const { name, app_url, width, height } = assistantTool
    try {
      osApi.navigation.openCompactApp({
        name,
        url: app_url,
        overlayProps: {
          id: 'app-toolbar-iframe',
          bounds: 'parent',
          default: {
            x: 250,
            y: 0,
            width,
            height,
          },
          style: {
            zIndex: 50,
          },
          enableResize: true,
          cancel: '.cancel-drag',
        },
      })
    } catch {
      dispatch(setErrorAlert(t('app.assistant_app.error')))
    }
  }

  // when there is only one assistant tool app
  if (isEqual(field.aiAssistantTools.length, 1)) {
    const assistantTool = first(field.aiAssistantTools) as IAiAssistantTool
    return (
      <WppTooltip
        component={
          <WppActionButton className={styles.btnAction} onClick={() => openApp(assistantTool)}>
            <div slot="icon-start" className={styles.btnIcon}>
              <WppIconStudio />
            </div>
            <Trans>app.button.inspire_me</Trans>
          </WppActionButton>
        }
        text={assistantTool.description}
        showTooltip={!isEmpty(assistantTool.description)}
      />
    )
  }

  // when there are multiple assistant tool app
  return (
    <WppAccordion size="s" className={styles.accordion}>
      <div slot="header">
        <WppTooltip
          text={t('app.tooltip.inspire_me')}
          component={
            <div className={styles.accordionHeader}>
              <div className={styles.btnIcon}>
                <WppIconStudio />
              </div>
              <WppTypography type="s-strong" className={styles.accordionTitle}>
                <Trans>app.button.inspire_me</Trans>
              </WppTypography>
            </div>
          }
        />
      </div>
      <div className={styles.accordionContent}>
        {orderBy(field.aiAssistantTools, 'order', 'asc').map((assistantTool: IAiAssistantTool) => (
          <WppTooltip
            key={assistantTool.name}
            component={
              <WppButton
                className={styles.btnAction}
                onClick={() => openApp(assistantTool)}
                variant="secondary"
                size="s"
              >
                {assistantTool.name}
              </WppButton>
            }
            text={assistantTool.description}
            showTooltip={!isEmpty(assistantTool.description)}
          />
        ))}
      </div>
    </WppAccordion>
  )
}

export default FormFieldAssistantTool
