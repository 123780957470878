import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import commonStyles from 'app/components/categorySection/CategorySection.module.scss'
import styles from 'app/components/categorySection/formField/formFieldAutoComplete/FormFieldAutoComplete.module.scss'
import { WppAvatar, WppPill } from 'app/components/common'
import IOption from 'interfaces/common/IOption'
import { AutocompleteDefaultOption } from 'services/types'

interface ISelectedValuesProps {
  values: AutocompleteDefaultOption[]
  disabled: boolean
  showAvatar: boolean
  onCloseClick?: (selectedValue: number | string) => void
}

/**
 * Selected autocomplete values
 * Shows selected values from autocomplete component
 * @param {object} props
 * @param {AutocompleteDefaultOption[]} props.values
 * @param {boolean} props.disabled
 * @param {boolean} props.showAvatar
 * @param {(selectedValue: number | string) => void} props.onCloseClick
 */
const SelectedValues: React.FC<ISelectedValuesProps> = ({
  values,
  onCloseClick,
  disabled,
  showAvatar,
}: ISelectedValuesProps) => {
  const selectedValuesReadOnlyClass = disabled ? commonStyles.selectedValuesReadOnly : ''
  return (
    <div slot="selected-values" className={classNames(styles.customStyleDefaultMessage, selectedValuesReadOnlyClass)}>
      {values.map((option: IOption) => (
        <WppPill
          label={option.displayLabel}
          removable={!disabled}
          value={option.id}
          onWppClose={() => onCloseClick && onCloseClick(option.id)}
          type="display"
          className={styles.pill}
          key={option.id}
          id={`pile-${option.id}`}
          name={option.displayLabel}
        >
          {showAvatar && <WppAvatar name={option.label} src={option.avatarUrl} slot="icon-start" />}
        </WppPill>
      ))}
      {disabled && _.isEqual(values.length, 0) && <div className={commonStyles.formReadOnlyEmpty} />}
    </div>
  )
}

export default SelectedValues
