// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zA67J{position:sticky;top:-1px;z-index:2}.zA67J .QwMx1{padding:13px 16px;text-align:left;background-color:var(--wpp-white-color);border-bottom:1px solid var(--wpp-grey-color-400)}.zA67J .QwMx1 .zb3NB{cursor:pointer;display:flex;align-items:center;gap:4px;width:fit-content}`, "",{"version":3,"sources":["webpack://./src/app/components/common/table/tableHeader/TableHeader.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CACA,QAAA,CACA,SAAA,CAEA,cACE,iBAAA,CACA,eAAA,CACA,uCAAA,CACA,iDAAA,CAEA,qBACE,cAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CACA,iBAAA","sourcesContent":[".tableHead {\n  position: sticky;\n  top: -1px;\n  z-index: 2;\n\n  .headerCell {\n    padding: 13px 16px;\n    text-align: left;\n    background-color: var(--wpp-white-color);\n    border-bottom: 1px solid var(--wpp-grey-color-400);\n\n    .headerContainer {\n      cursor: pointer;\n      display: flex;\n      align-items: center;\n      gap: 4px;\n      width: fit-content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHead": `zA67J`,
	"headerCell": `QwMx1`,
	"headerContainer": `zb3NB`
};
export default ___CSS_LOADER_EXPORT___;
