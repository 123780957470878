import { WppEmptyNothingFound, WppGrid, WppTypography } from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from 'app/components/common/errorComponents/notFound/NotFound.module.scss'

interface INotFoundProps {
  title: string
  description: string
}

/**
 * Not found component
 * Shows not found component for table in case there are no records.
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.description
 */
const NotFound: React.FC<INotFoundProps> = ({ title, description }: INotFoundProps): React.ReactElement => {
  return (
    <WppGrid container className={styles.container} rowSpacing={2}>
      <WppGrid item all={8} className={styles.items}>
        <WppEmptyNothingFound />
        <div className={styles.detailsContainer}>
          <WppTypography type="m-strong" tag="p">
            {title}
          </WppTypography>
          <WppTypography type="s-body" className={styles.description}>
            {description}
          </WppTypography>
        </div>
      </WppGrid>
    </WppGrid>
  )
}

export default NotFound
