// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h11qO::part(message){color:#4d5358;margin-top:4px}.vVtE7::part(wrapper){margin-top:4px}`, "",{"version":3,"sources":["webpack://./src/app/components/categorySection/formField/formFieldMessage/FormFieldMessage.module.scss"],"names":[],"mappings":"AACE,sBACE,aAAA,CACA,cAAA,CAKF,sBACE,cAAA","sourcesContent":[".customStyleDefaultMessage {\n  &::part(message) {\n    color: #4d5358;\n    margin-top: 4px;\n  }\n}\n\n.errorMessage {\n  &::part(wrapper) {\n    margin-top: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customStyleDefaultMessage": `h11qO`,
	"errorMessage": `vVtE7`
};
export default ___CSS_LOADER_EXPORT___;
