import _ from 'lodash'
import React from 'react'

import { WppLabel, WppListItem, WppSelect, WppTag } from 'app/components/common'
import styles from 'app/components/projectBrief/select/Select.module.scss'
import IOption from 'interfaces/common/IOption'
import { SelectChangeEventDetail, WppSelectCustomEvent } from 'services/types'

interface ISelectProps {
  data: IOption[]
  onChange: (value: IOption) => void
  value: string | null
  label: string
  placeholder: string
  disabled?: boolean
}

/**
 * Create select component for questionnaires
 * @param {object} props
 * @param {IOption[]} props.data
 * @param {(value: IOption) => void} props.onChange
 * @param {IOption} props.value
 * @param {string} props.label
 * @param {string} props.placeholder
 */
const Select: React.FC<ISelectProps> = ({
  data,
  onChange,
  value,
  label,
  placeholder,
  disabled,
}: ISelectProps): React.ReactElement => {
  const OptionValue: IOption | undefined = data.find((option: IOption) => _.isEqual(option.id, value))
  return (
    <div className={styles.selectContainer}>
      <WppLabel className={styles.label} config={{ text: label }} typography="s-strong" />
      <WppSelect
        onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => onChange(event.detail.value)}
        placeholder={placeholder}
        data-testid="questionnaires-select"
        value={OptionValue ?? null}
        disabled={disabled}
        withFolder
        withSearch
      >
        {data.map((option: IOption) => (
          <WppListItem value={option} key={option.id}>
            <p slot="label">{option.label}</p>
            {option.subLabel && <p slot="caption">{option.subLabel}</p>}
            {option.tag && (
              <p slot="right">
                <WppTag label={option.tag.label} variant={option.tag.variant} />
              </p>
            )}
          </WppListItem>
        ))}
      </WppSelect>
    </div>
  )
}

export default Select
