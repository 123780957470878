import classnames from 'classnames'
import _ from 'lodash'
import { Trans } from 'react-i18next'

import { WppTypography } from 'app/components/common'
import styles from 'app/components/common/mentionEditor/MentionEditor.module.scss'
import { COMMENT_TEXT_EDITOR_MAX_LENGTH } from 'config/constants'

interface InputActionProps {
  textCharCount: number
}

/**
 * Input action component for field comment editor
 * Shows number of character or error in case over max length in field comment textarea
 * @param {object} props
 * @param {number} props.textCharCount
 */
const InputAction = ({ textCharCount }: InputActionProps) => {
  let textColorStyle = _.gte(textCharCount, COMMENT_TEXT_EDITOR_MAX_LENGTH - 100)
    ? styles.characterLimitLabelWarning
    : ''

  if (_.gt(textCharCount, COMMENT_TEXT_EDITOR_MAX_LENGTH)) {
    textColorStyle = styles.characterLimitLabelError
  }

  return (
    <div className={styles.inputAction}>
      <div className={classnames(styles.characterLimit, textColorStyle)}>
        <WppTypography className={textColorStyle} tag="p" type="xs-body">
          <Trans>comment.text.characters</Trans>
        </WppTypography>
        <WppTypography className={textColorStyle} tag="p" type="xs-strong">
          {textCharCount}/{COMMENT_TEXT_EDITOR_MAX_LENGTH}
        </WppTypography>
      </div>
    </div>
  )
}

export default InputAction
