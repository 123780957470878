// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._PcB2{display:flex;align-items:center;padding:0;margin-left:.5rem;cursor:pointer}._PcB2 .g9HSv{display:flex}._PcB2 .Vio5G{cursor:pointer}._PcB2 .Vio5G::part(typography){font-size:.875rem}._PcB2 .Vio5G .oJ5Ah{display:flex;align-items:center;gap:4px}.Vfjpj{margin-bottom:.5rem;width:100%}.Vfjpj * a{text-decoration:underline !important}.Vfjpj * i{font-style:italic}.Vfjpj * b{font-weight:500}.XeIcI{display:inline;font-family:inherit;font-size:inherit;font-weight:inherit;cursor:default;text-align:left}`, "",{"version":3,"sources":["webpack://./src/app/components/categorySection/formField/formFieldRadioCheckLabel/FormFieldRadioCheckLabel.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,SAAA,CACA,iBAAA,CACA,cAAA,CAEA,cACE,YAAA,CAGF,cAIE,cAAA,CAHA,gCACE,iBAAA,CAIF,qBACE,YAAA,CACA,kBAAA,CACA,OAAA,CAKN,OACE,mBAAA,CACA,UAAA,CACA,WACE,oCAAA,CAEF,WACE,iBAAA,CAEF,WACE,eAAA,CAIJ,OACE,cAAA,CACA,mBAAA,CACA,iBAAA,CACA,mBAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  padding: 0;\n  margin-left: 0.5rem;\n  cursor: pointer;\n\n  .tooltip {\n    display: flex;\n  }\n\n  .label {\n    &::part(typography) {\n      font-size: 0.875rem;\n    }\n    cursor: pointer;\n\n    .labelContainer {\n      display: flex;\n      align-items: center;\n      gap: 4px;\n    }\n  }\n}\n\n.description {\n  margin-bottom: 0.5rem;\n  width: 100%;\n  & * a {\n    text-decoration: underline !important;\n  }\n  & * i {\n    font-style: italic;\n  }\n  & * b {\n    font-weight: 500;\n  }\n}\n\n.btnLabel {\n  display: inline;\n  font-family: inherit;\n  font-size: inherit;\n  font-weight: inherit;\n  cursor: default;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `_PcB2`,
	"tooltip": `g9HSv`,
	"label": `Vio5G`,
	"labelContainer": `oJ5Ah`,
	"description": `Vfjpj`,
	"btnLabel": `XeIcI`
};
export default ___CSS_LOADER_EXPORT___;
