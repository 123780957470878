import React from 'react'

/**
 * Wpp Custom stdio app icon
 * Studio app icon for ai assistant apps
 */
const WppIconStudio: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.21776 4.87913L15.121 17.7824C15.8557 18.517 17.0477 18.517 17.7824 17.7824C18.517 17.0477 18.517 15.8557 17.7824 15.121L4.87913 2.21776C4.14445 1.48308 2.95244 1.48308 2.21776 2.21776C1.48308 2.95244 1.48308 4.14445 2.21776 4.87913Z"
        fill="url(#paint0_linear_1366_350)"
      />
      <path
        d="M15.121 2.21776L2.21776 15.121C1.48308 15.8557 1.48308 17.0477 2.21776 17.7824C2.95244 18.517 4.14445 18.517 4.87913 17.7824L17.7824 4.87913C18.517 4.14445 18.517 2.95244 17.7824 2.21776C17.0477 1.48308 15.8557 1.48308 15.121 2.21776Z"
        fill="url(#paint1_linear_1366_350)"
      />
      <path
        d="M10 11.8819C11.0393 11.8819 11.8819 11.0393 11.8819 10C11.8819 8.96069 11.0393 8.11816 10 8.11816C8.96069 8.11816 8.11816 8.96069 8.11816 10C8.11816 11.0393 8.96069 11.8819 10 11.8819Z"
        fill="#FFDA00"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1366_350"
          x1="17.7832"
          y1="17.7825"
          x2="2.21832"
          y2="2.21758"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFAA" />
          <stop offset="1" stopColor="#0080FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1366_350"
          x1="2.21802"
          y1="17.7825"
          x2="17.7829"
          y2="2.21765"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D0DC5" />
          <stop offset="1" stopColor="#FF1B92" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default WppIconStudio
