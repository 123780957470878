import { useOs } from '@wppopen/react'
import _ from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import CategorySection from 'app/components/categorySection'
import { WppGrid, WppTypography } from 'app/components/common'
import { ApplicationError } from 'app/components/common/errorComponents'
import { ConfirmationModal } from 'app/components/common/modals'
import Spinner from 'app/components/common/spinner/Spinner'
import { VerticalStepper, StepperAction } from 'app/components/stepper'
import Toolbar from 'app/components/toolbar'
import ToolbarAction from 'app/components/toolbar/toolbarAction'
import UserPermissionStatus from 'app/components/toolbar/userPermissionStatus'
import RejectBanner from 'app/features/review/components/reject/rejectBanner'
import ReviewStatus from 'app/features/review/components/status'
import { APP_ACTIONS, STEP_ACTIONS, TOAST_MESSAGE_TYPES } from 'config/enums'
import { useMount } from 'hooks'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import ChangeLog from 'pages/changeLog'
import styles from 'pages/questionnaire/Questionnaire.module.scss'
import ActionService from 'services/questionnaire/ActionService'
import QuestionnaireService from 'services/questionnaire/QuestionnaireService'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import { setAppStatus } from 'store/reducers/questionnaireSlice'

/**
 * Questionnaire app
 * Questionnaire component. Use to show selected questionnaire with categories and forms
 */
const Questionnaire: React.FC = (): React.ReactElement => {
  const { t } = useTranslation()
  const location = useLocation()
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)
  const projectBriefState = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const projectQuestionnaireId: string = _.toString(projectBriefState.questionnaire.projectQuestionnaireId)
  const { osContext, osApi } = useOs()

  const ACCESS_TOKEN = osApi.getAccessToken()
  const dispatch = useDispatch<AppDispatch>()
  const { app, appStatus, activeStep, error, loading } = questionnaireState

  /**
   * Handle Stepper change
   * @param {number} stepNumber
   */
  const handleStep = (stepNumber: number, stepAction: STEP_ACTIONS, nextCategory: ICategory) => {
    QuestionnaireService.handleStep({
      accessToken: ACCESS_TOKEN,
      dispatch,
      appContext,
      projectQuestionnaireId,
      questionnaireState,
      stepAction,
      stepNumber,
      nextCategory,
      t,
    })
  }

  const closeApp = () => {
    osApi.navigation.openCompactApp({
      name: '',
      url: '',
      overlayProps: {
        id: 'app-toolbar-iframe',
        bounds: 'parent',
        // enableUserSelectHack: false,
        default: {
          width: 0,
          height: 0,
        },
        style: {
          display: 'none',
        },
        enableResize: true,
        cancel: '.cancel-drag',
      },
    })
  }

  const redirectToProject = (appAction: string) => {
    const timer = setTimeout(
      () => {
        window.location.href = `${osContext.tenant.homeUrl}orchestration/project/${appContext.projectId}/workflow`
        clearTimeout(timer)
      },
      _.isEqual(appAction, APP_ACTIONS.CANCEL) ? 0 : 1500,
    )
  }

  /**
   * Handle app actions i.e Submit, Save Progress, Cancel
   * @param {string} appAction
   * @param {(message: TOAST_MESSAGE_TYPES, app: IApp | null) => void} callback
   * @param {boolean} displayMessage
   */
  const handleAppAction = async (
    appAction: string,
    callback: (message: TOAST_MESSAGE_TYPES, app: IApp | null) => void,
    displayMessage: boolean = true,
  ): Promise<void> => {
    const updatedQuestionnaire = await ActionService.handleAppAction(
      {
        accessToken: ACCESS_TOKEN,
        appAction,
        dispatch,
        displayMessage,
        appContext,
        questionnaireState,
        projectQuestionnaireId,
        t,
      },
      callback,
    )

    if (updatedQuestionnaire && !updatedQuestionnaire.appStatus.openModal) {
      if (
        _.isEqual(appAction, APP_ACTIONS.CANCEL) ||
        (_.isEqual(appAction, APP_ACTIONS.SAVE_AND_EXIT) && updatedQuestionnaire.app?.isValid)
      ) {
        redirectToProject(appAction)
      }
    }
  }

  const initLoad = useCallback(async () => {
    QuestionnaireService.initLoad(
      ACCESS_TOKEN,
      appContext,
      questionnaireState,
      projectBriefState,
      projectQuestionnaireId,
      dispatch,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCommentMode = () => {
    QuestionnaireService.handleCommentMode(questionnaireState, dispatch)
  }

  const closeAssistantApp = () => {
    // @ts-ignore
    if (osApi.navigation.openCompactApp) {
      closeApp()
    }
  }

  useEffect(() => {
    if (!(app?.isAppEditor && app?.isProjectMember)) {
      closeAssistantApp()
    }
    return () => {
      closeAssistantApp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, app?.isAppEditor, app?.isProjectMember])

  useMount(() => {
    initLoad()
  })

  if (error) {
    return <ApplicationError />
  }

  if (_.isNull(app)) {
    return <Spinner />
  }

  return (
    <>
      {_.isEqual(location.pathname, '/change-log') && <ChangeLog />}
      {_.isEqual(location.pathname, '/') && (
        <>
          <RejectBanner />
          <Toolbar>
            <div className={styles.headerItem}>
              <div className={styles.item}>
                <WppTypography tag="h3" type="m-strong">
                  {app.appName}
                </WppTypography>
                <ReviewStatus />
                <UserPermissionStatus />
              </div>
              <div>
                {!_.isEmpty(app.categories) && (
                  <div className={styles.actions}>
                    <ToolbarAction handleAppAction={handleAppAction} handleCommentMode={handleCommentMode} />
                  </div>
                )}
              </div>
            </div>
          </Toolbar>
          <WppGrid container className={styles.layout} id="form-layout" rowSpacing={2}>
            {!_.isEmpty(app.categories) && !_.isEqual(app.categories.length, 1) && (
              <WppGrid className={styles.layoutCategories} item all={4}>
                <VerticalStepper activeStep={activeStep} app={app} handleStep={handleStep} />
              </WppGrid>
            )}
            {!_.isEmpty(app.categories) && (
              <WppGrid item all={!_.isEqual(app.categories.length, 1) ? 20 : 24}>
                <div className={styles.formContainer} id="form-container">
                  <CategorySection />
                  <StepperAction
                    handleAppAction={handleAppAction}
                    noOfCategories={app.categories.length}
                    handleStep={handleStep}
                  />
                </div>
              </WppGrid>
            )}
          </WppGrid>
          <ConfirmationModal
            title={t('app.leave_confirmation.title', { appName: app.appName })}
            body={t('app.leave_confirmation.body', { appName: app.appName })}
            btnPrimaryText={t('app.button.leave')}
            data-testid="confirmation-modal"
            btnSecondaryText={t('app.button.cancel')}
            handlePrimaryAction={() => {
              window.location.href = `${osContext.tenant.homeUrl}orchestration/project/${appContext.projectId}/workflow`
            }}
            handleSecondaryAction={() => dispatch(setAppStatus({ ...appStatus, openModal: false }))}
            isOpen={appStatus.openModal}
          />
        </>
      )}
      {loading && <Spinner />}
    </>
  )
}

export default Questionnaire
