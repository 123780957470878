import * as _ from 'lodash'
import React, { useCallback, useState } from 'react'

import styles from 'app/components/categorySection/formField/formFieldTooltip/FormFieldTooltip.module.scss'
import { WppIconInfo, WppTooltip, WppTypography } from 'app/components/common'
import { HelpModal } from 'app/components/common/modals'
import IHelpModal from 'interfaces/field/IHelpModal'

interface IFormFieldTooltipProps {
  tooltip: string
  helpModal?: IHelpModal
}

/**
 * Create help content with side panel
 * @param {object} props
 * @param {string} props.tooltip
 * @param {IHelpModal} props.helpModal
 */
const FormFieldTooltip: React.FC<IFormFieldTooltipProps> = ({
  helpModal,
  tooltip,
}: IFormFieldTooltipProps): React.ReactElement => {
  const [modalOpen, setModalOpen] = useState(false)

  /**
   * Handle close/open action for modal
   * @returns {void}
   */
  const handleModalAction = useCallback((): void => {
    if (!_.isNull(helpModal)) {
      setModalOpen(!modalOpen)
    }
  }, [modalOpen, helpModal])

  if (_.isEmpty(tooltip) && !helpModal) {
    return <></>
  }

  let infoIcon = <WppIconInfo className={styles.infoIcon} />

  if (tooltip && !_.isEmpty(tooltip)) {
    infoIcon = (
      <WppTooltip
        component={infoIcon}
        allowHTML
        tooltipHTMLContent={
          <WppTypography type="s-body" tag="p" className={styles.tooltipContainer}>
            <span
              dangerouslySetInnerHTML={{
                __html: tooltip,
              }}
            />
          </WppTypography>
        }
      />
    )
  }

  return (
    <>
      {helpModal?.title && (
        <HelpModal
          title={helpModal?.title}
          content={helpModal?.content}
          isModalOpen={modalOpen}
          handleModalAction={handleModalAction}
        />
      )}
      <button
        onClick={event => {
          event.stopPropagation()
          handleModalAction()
        }}
        className={styles.btnIcon}
      >
        {infoIcon}
      </button>
    </>
  )
}

export default FormFieldTooltip
