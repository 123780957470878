import { WppIconTableSort, WppIconTableSortAsc, WppIconTableSortDesc } from '@platform-ui-kit/components-library-react'
import _ from 'lodash'
import React from 'react'

import { ORDER_BY } from 'config/enums'
import ITable from 'interfaces/table/ITable'

interface ITableSortProps {
  table: ITable
  column: string
}

/**
 * Table sort component
 * Shows sort icons for table header
 * @param {object} props
 * @param {boolean} props.table
 */
const TableSort: React.FC<ITableSortProps> = ({ table, column }: ITableSortProps): React.ReactElement => {
  const { orderBy, orderByColumn } = table
  return (
    <>
      {_.isEqual(orderByColumn, column) && (
        <>
          {_.isEqual(orderBy, ORDER_BY.ASC) && <WppIconTableSortAsc />}
          {_.isEqual(orderBy, ORDER_BY.DESC) && <WppIconTableSortDesc />}
        </>
      )}
      {!_.isEqual(orderByColumn, column) && <WppIconTableSort />}
    </>
  )
}

export default TableSort
