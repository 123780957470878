import { WppEmptyError, WppGrid, WppTypography } from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from 'app/components/common/errorComponents/component/Component.module.scss'

interface IComponentErrorProps {
  title: string
  description: string
}

/**
 * Component Error
 * Shows error for the components. Used in ChangeLog.tsx in case any error from the API
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.description
 */
const ComponentError: React.FC<IComponentErrorProps> = ({
  title,
  description,
}: IComponentErrorProps): React.ReactElement => {
  return (
    <WppGrid container className={styles.container} rowSpacing={2}>
      <WppGrid item all={8} className={styles.items}>
        <WppEmptyError />
        <div className={styles.detailsContainer}>
          <WppTypography type="m-strong" tag="p">
            {title}
          </WppTypography>
          <WppTypography type="m-body">{description}</WppTypography>
        </div>
      </WppGrid>
    </WppGrid>
  )
}

export default ComponentError
