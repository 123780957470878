// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.S1hbF{display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./src/app/components/categorySection/formBuilder/FormBuilder.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA","sourcesContent":[".form {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `S1hbF`
};
export default ___CSS_LOADER_EXPORT___;
