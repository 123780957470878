import { WppIconAvailableCheckmark as WppIconCheckmark } from '@platform-ui-kit/components-library-react'
import React from 'react'

interface IWppIconAvailableCheckmark {
  backgroundColor?: string
}

/**
 * Wpp Custom checkmark icon
 * Checkmark icon for completed fields. Used in FormFieldLabel.tsx file.
 * @param {object} props
 * @param {string} props.backgroundColor
 */
const WppIconAvailableCheckmark: React.FC<IWppIconAvailableCheckmark> = ({
  backgroundColor,
}: IWppIconAvailableCheckmark) => {
  if (backgroundColor) {
    return (
      <svg width="20" height="21" viewBox="0 0 20 21" fill={backgroundColor} xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2" width="16" height="16" rx="8" fill={backgroundColor} />
        <path
          d="M12.8107 8.2319C13.0751 8.52752 13.0608 8.99184 12.7788 9.26899L9.2848 12.7023C8.88091 13.0992 8.25242 13.0992 7.84853 12.7023L6.22124 11.1033C5.9392 10.8261 5.92492 10.3618 6.18933 10.0662C6.45374 9.77056 6.89672 9.75559 7.17876 10.0327L8.56667 11.3966L11.8212 8.19844C12.1033 7.9213 12.5463 7.93628 12.8107 8.2319Z"
          fill="white"
        />
      </svg>
    )
  }
  return <WppIconCheckmark />
}

export default WppIconAvailableCheckmark
