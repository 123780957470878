import React from 'react'
import { Trans } from 'react-i18next'

import { WppIconExportFile, WppListItem, WppTypography } from 'app/components/common'

interface IDocProps {
  handleDocExport: Function
}

/**
 * DOCX Export Button
 * Shows export button for DOCX file
 */
const Doc: React.FC<IDocProps> = ({ handleDocExport }: IDocProps): React.ReactElement => {
  return (
    <WppListItem data-testid="btn-docx" onWppChangeListItem={() => handleDocExport()}>
      <p slot="left">
        <WppIconExportFile />
      </p>
      <p slot="label">
        <WppTypography tag="span" type="s-body">
          <Trans>action.export.title_docx</Trans>
        </WppTypography>
      </p>
    </WppListItem>
  )
}

export default Doc
