import React from 'react'

import { WppSpinner } from 'app/components/common'
import styles from 'app/components/common/spinner/Spinner.module.scss'

/**
 * Spinner Component
 */
const Spinner: React.FC = (): React.ReactElement => {
  return (
    <div className={styles.container}>
      <WppSpinner className={styles.spinner} size="l" color="var(--wpp-primary-color-500)" />
      <div className={styles.overlay} />
    </div>
  )
}

export default Spinner
