import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { WppTag } from 'app/components/common'
import { REVIEW_RESPONSE_TYPES } from 'config/enums'

interface IReviewerStatusProps {
  status: REVIEW_RESPONSE_TYPES | null
}

/**
 * Reviewer status component
 * Shows review status i.e Approved, Reject or Pending
 * @param {object} props
 * @param {REVIEW_RESPONSE_TYPES | null} props.status
 */
const ReviewerStatus: React.FC<IReviewerStatusProps> = ({ status }: IReviewerStatusProps): React.ReactElement => {
  const { t } = useTranslation()

  if (_.isNull(status) || _.isEqual(status, REVIEW_RESPONSE_TYPES.PENDING)) {
    return <WppTag variant="neutral" label={t('review.reviewers_list.response_types.pending')} />
  }

  if (_.isEqual(status, REVIEW_RESPONSE_TYPES.APPROVAL)) {
    return <WppTag variant="positive" label={t('review.reviewers_list.response_types.approved')} />
  }

  return <WppTag variant="negative" label={t('review.reviewers_list.response_types.rejected')} />
}

export default ReviewerStatus
