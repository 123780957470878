import _ from 'lodash'
import React from 'react'

import styles from 'app/components/categorySection/formField/formFieldRadio/FormFieldRadio.module.scss'
import FormFieldRadioCheckLabel from 'app/components/categorySection/formField/formFieldRadioCheckLabel'
import { WppRadio } from 'app/components/common'
import IOption from 'interfaces/common/IOption'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'

interface IFormFieldRadioProps {
  field: IField
  option: IOption
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create radio field
 * @param {object} props
 * @param {IField} props.field
 * @param {IOption} props.option
 * @param {(event: IFieldChangeEvent) => void} props.handleChange
 */
const Radio: React.FC<IFormFieldRadioProps> = ({
  field,
  option,
  handleChange,
}: IFormFieldRadioProps): React.ReactElement => {
  const { disabled, fieldConfig, value, isRequired } = field
  const { text } = fieldConfig

  return (
    <div className={styles.radio}>
      <WppRadio
        required={isRequired}
        name={text}
        disabled={disabled}
        value={option.id}
        checked={_.isEqual(option.id, value)}
        labelConfig={{
          text: '',
        }}
        className={styles.radioButton}
      />
      <FormFieldRadioCheckLabel
        id={`${field.id}-${_.toString(option.id).split(' ').join('_')}`}
        text={option.label}
        helpModal={option.help_model}
        disabled={field.disabled}
        handleChange={() => {
          handleChange({
            field,
            value: option.id,
          })
        }}
      />
    </div>
  )
}

export default Radio
