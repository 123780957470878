// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GKiks{height:100%;overflow:auto;padding-bottom:8px}.GKiks .g_m8j{width:100%;border-collapse:separate;max-height:70vh;overflow:auto}.waYWe{padding:8px 0px}`, "",{"version":3,"sources":["webpack://./src/app/components/common/table/Table.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,aAAA,CACA,kBAAA,CACA,cACE,UAAA,CACA,wBAAA,CACA,eAAA,CACA,aAAA,CAGJ,OACE,eAAA","sourcesContent":[".tableContainer {\n  height: 100%;\n  overflow: auto;\n  padding-bottom: 8px;\n  .table {\n    width: 100%;\n    border-collapse: separate;\n    max-height: 70vh;\n    overflow: auto;\n  }\n}\n.tablePagination {\n  padding: 8px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `GKiks`,
	"table": `g_m8j`,
	"tablePagination": `waYWe`
};
export default ___CSS_LOADER_EXPORT___;
