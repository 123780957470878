import _ from 'lodash'

import { FIELD_TYPES } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import IOption from 'interfaces/common/IOption'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import IForm from 'interfaces/form/IForm'
import CommonService from 'services/CommonService'
import FormFieldService from 'services/formField/FormFieldService'
import FormFieldValidationService from 'services/formField/FormFieldValidationService'

export default class FormValidationService {
  private readonly formFieldValidationService: FormFieldValidationService
  private readonly formFieldService: FormFieldService
  private readonly commonService: CommonService

  /**
   *  Creates an instance of FormValidationService
   */
  constructor() {
    this.formFieldValidationService = new FormFieldValidationService()
    this.formFieldService = new FormFieldService()
    this.commonService = new CommonService()
  }

  /**
   * Validate previously selected category
   * @param {IField} field
   * @param {ICategory} nextCategory
   * @returns {IApp}
   */
  validatePreviousCategories(app: IApp, nextCategory: ICategory): IApp {
    let isAppValid = true

    const updatedCategories: ICategory[] = app.categories.map((category: ICategory) => {
      if (_.lt(category.categoryIndex, nextCategory.categoryIndex)) {
        const updatedCategory: ICategory = this.validateCategory(category)
        isAppValid = isAppValid && updatedCategory.isValid
        return this.validateCategory(updatedCategory)
      }

      isAppValid = isAppValid && category.isValid
      return category
    })

    return {
      ...app,
      isValid: isAppValid,
      categories: updatedCategories,
    }
  }

  /**
   * Validate category
   * @param {ICategory} category
   * @returns {ICategory}
   */
  validateCategory(category: ICategory): ICategory {
    let isValidCategory = true
    const forms: IForm[] = category.forms.map((form: IForm) => {
      let isValidForm = true
      let fields: IField[] = form.fields.map((field: IField) => {
        let updatedField: IField = this.formFieldValidationService.validateField(field)
        isValidForm = isValidForm && updatedField.isValid

        let children: IField[] = []
        for (let childField of field.children) {
          let updatedChildField: IField = this.formFieldValidationService.validateField(childField)
          children.push(updatedChildField)
          isValidForm = isValidForm && updatedChildField.isValid
        }

        return {
          ...updatedField,
          touched: true,
          isValid: updatedField.isValid,
          children,
        }
      })
      isValidCategory = isValidCategory && isValidForm
      return {
        ...form,
        fields,
      }
    })

    return {
      ...category,
      forms,
      isValid: isValidCategory,
    }
  }
  /**
   * Validate app by category id
   * @param {IApp} app
   * @param {string | null} categoryId
   * @returns {IApp}
   */
  validateAppByCategoryId(app: IApp, categoryId: string | null = null): IApp {
    let isAppValid = true
    let currentCategoryId: string = app.currentCategoryId

    const Categories = app.categories.map((category: ICategory) => {
      const catId = !_.isNull(categoryId) ? categoryId : category.id

      if (_.isEqual(catId, category.id)) {
        let updatedCategory = this.validateCategory(category)
        currentCategoryId = !_.isNull(categoryId) ? updatedCategory.id : app.currentCategoryId
        isAppValid = isAppValid && updatedCategory.isValid
        return updatedCategory
      }
      return category
    })

    return {
      ...app,
      isValid: isAppValid,
      categories: Categories,
      currentCategoryId,
    }
  }

  /**
   * Validate category forms
   * @param {ICategory[]} categories
   * @returns {ICategory[]}
   */
  validateCategories(categories: ICategory[]): ICategory[] {
    return categories.map((category: ICategory) => {
      let isValid = true

      for (let form of category.forms) {
        for (let field of form.fields) {
          let isFieldValid = field.touched ? field.isValid : true
          isValid = isValid && isFieldValid
          for (let childField of field.children) {
            let isChildFieldValid = childField.touched ? childField.isValid : true
            isValid = isValid && isChildFieldValid
          }
        }
      }

      return {
        ...category,
        isValid,
      }
    })
  }

  /**
   * If child field is visible based on parent field value
   * @param {IField} parent
   * @param {IField} child
   * @returns {boolean}
   */
  isChildVisible(parent: IField, child: IField): boolean {
    if (_.isEqual(parent.type, FIELD_TYPES.CHECKBOX)) {
      const Values = parent.value.map((option: IOption) => _.toString(option.id))
      return this.commonService.findAnyArrayValueInTargetArray(child.visibleOnValue, Values)
    }
    return this.commonService.findAnyArrayValueInTargetArray(child.visibleOnValue, [_.toString(parent.value)])
  }

  /**
   * Validate Child Field
   * @param {IField} parentField
   * @param {IField} childField
   * @returns {IField}
   */
  validateChild(parentField: IField, childField: IField): IField {
    let updatedField = this.formFieldValidationService.validate(childField, childField.value)

    let isValid = this.isChildVisible(parentField, childField) ? updatedField.isValid : true
    isValid = updatedField.touched ? isValid : true
    let errorMessage = isValid ? '' : updatedField.errorMessage
    const messageType = isValid ? undefined : updatedField.messageType

    return {
      ...childField,
      isValid,
      errorMessage,
      messageType,
      isHidden: !this.isChildVisible(parentField, childField),
    }
  }

  /**
   * Validate Dependent Fields based on parent field value
   * @param {IForm} form
   * @param {ICategory[]} categories
   * @param {IFieldChangeEvent} event
   * @returns {IField[]}
   */
  validateDependentFields(form: IForm, categories: ICategory[], event: IFieldChangeEvent): IField[] {
    return form.fields.map((field: IField) => {
      if (_.isEqual(field.id, event.field.id)) {
        const VisibleOn = field.visibleOn ?? null

        if (!_.isNull(VisibleOn)) {
          const ParentField = this.formFieldService.findFieldByFormFieldId(categories, VisibleOn)
          field = !ParentField ? field : this.validateChild(ParentField, field)
        }
        field.children = field.children.map((childField: IField) => {
          return this.validateChild(field, childField)
        })
        return field
      }
      return field
    })
  }

  /**
   * Validate Dependent forms
   * @param {ICategory[]} categories
   * @param {ICategory} category
   * @param {IFieldChangeEvent} event
   * @returns {IForm[]}
   */
  validateDependentForms(categories: ICategory[], category: ICategory, event: IFieldChangeEvent): IForm[] {
    return category.forms.map((form: IForm) => ({
      ...form,
      fields: this.validateDependentFields(form, categories, event),
    }))
  }

  /**
   * Validate Dependent Children
   * @param {ICategory[]} categories
   * @param {IFieldChangeEvent} event
   * @returns {ICategory[]}
   */
  validateDependentField(categories: ICategory[], event: IFieldChangeEvent): ICategory[] {
    return categories.map((category: ICategory) => ({
      ...category,
      forms: this.validateDependentForms(categories, category, event),
    }))
  }

  /**
   * Validate field in the form
   * @param {IField} field
   * @returns {{ field: IField; isValid: boolean }}
   */
  validateFormField(field: IField): { field: IField; isValid: boolean } {
    let children: IField[] = []
    let isValid = true
    field.children.forEach((childField: IField) => {
      let isChildValid = childField.isHidden ? true : childField.isValid
      isValid = isValid && isChildValid

      children.push({
        ...childField,
        isValid,
        errorMessage: isChildValid ? '' : childField.errorMessage,
        messageType: isChildValid ? undefined : 'error',
      })
    })

    let isFieldValid = field.isHidden ? true : field.isValid
    isValid = isValid && isFieldValid

    return {
      field: {
        ...field,
        isValid: isFieldValid,
        children,
        errorMessage: isFieldValid ? '' : field.errorMessage,
        messageType: isFieldValid ? undefined : 'error',
      },
      isValid,
    }
  }

  /**
   * Validate forms
   * @param {IApp} app
   * @param {ICategory[]} categories
   * @returns {IApp}
   */
  validateForms(app: IApp, categories: ICategory[]): IApp {
    let isValidApp = true

    let updatedCategories: ICategory[] = categories.map((category: ICategory) => {
      let isValidCategory = true
      let forms: IForm[] = []
      category.forms.forEach((form: IForm) => {
        let fields: IField[] = []
        form.fields.forEach((field: IField) => {
          let update = this.validateFormField(field)
          isValidCategory = isValidCategory && update.isValid
          fields.push(update.field)
        })
        forms.push({
          ...form,
          fields,
        })
      })
      isValidApp = isValidApp && isValidCategory
      return {
        ...category,
        isValid: isValidCategory,
        forms,
      }
    })
    return {
      ...app,
      categories: updatedCategories,
      isValid: isValidApp,
    }
  }
}
