import React from 'react'

import Field from 'app/components/categorySection/formBuilder/formField/Field'
import styles from 'app/components/categorySection/formBuilder/formField/FormField.module.scss'
import { WppGrid } from 'app/components/common'
import IField from 'interfaces/field/IField'
import IForm from 'interfaces/form/IForm'

interface IFormFieldProps {
  form: IForm
}

/**
 * Build field
 * @param {object} props
 * @param {IForm} props.form
 */
const FormField: React.FC<IFormFieldProps> = (props: IFormFieldProps): React.ReactElement => {
  return (
    <WppGrid item all={24} className={styles.fieldsContainer}>
      {props.form.fields.map((field: IField) => (
        <Field key={field.id} field={field} isChildField={false} />
      ))}
    </WppGrid>
  )
}

export default FormField
