import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'

import FilterHelper from 'app/features/changeLog/services/FilterService'
import { DEFAULT_PAGINATOR_DATA } from 'config/constants'
import { FIELD_TYPES, ORDER_BY } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import IChangeLogSelectedFilter from 'interfaces/changeLog/IChangeLogSelectedFilter'
import IField from 'interfaces/field/IField'
import ITable from 'interfaces/table/ITable'
import IChangeLogState from 'store/interfaces/IChangeLogState'

const defaultChangeLogState: IChangeLogState = {
  filterSelectedData: {
    dateRange: FilterHelper.getFilterDefaultDateRange(),
    sideModal: {
      selectedCategories: [],
      selectedFormFields: [],
      selectedUserField: {
        id: '600',
        formFieldId: '600',
        isValid: false,
        isCompleted: false,
        touched: false,
        type: FIELD_TYPES.USER,
        maxRepeatableAmount: 0,
        repeatableFields: [],
        isRequired: false,
        disabled: false,
        fieldComment: {
          comments: [],
          page: 1,
          hasMore: true,
          startDate: null,
          uniqueUsers: [],
        },
        value: [],
        errorMessage: '',
        displayOrder: 10,
        children: [],
        visibleOn: null,
        visibleOnValue: [],
        fieldConfig: {
          text: '',
          categoryId: '',
          projectQuestionnaireId: '',
          tenantId: '',
          tooltip: '',
          placeholder: '',
          help: '',
          multi: true,
          options: [],
        },
      },
      viewOnlyFiles: false,
    },
  },
  filterCount: 0,
  sideModalData: {
    categories: [],
    formFields: [],
  },
  tableData: {
    colDefs: [],
    rowData: [],
    paginator: DEFAULT_PAGINATOR_DATA,
    orderBy: ORDER_BY.DESC,
    loading: true,
    orderByColumn: 'date',
    hasMore: false,
  },
  openFilterModal: false,
  error: false,
}

export const changeLogSlice = createSlice({
  name: 'changeLog',
  initialState: defaultChangeLogState,
  reducers: {
    resetState() {
      return defaultChangeLogState
    },
    setFilterDateRange: (
      state: IChangeLogState,
      action: PayloadAction<string[] | string | undefined>,
    ): IChangeLogState => {
      return {
        ...state,
        filterSelectedData: {
          ...state.filterSelectedData,
          dateRange: action.payload,
        },
      }
    },
    setOpenFilterModal: (state: IChangeLogState, action: PayloadAction<boolean>): IChangeLogState => {
      return {
        ...state,
        openFilterModal: action.payload,
      }
    },

    setError: (state: IChangeLogState, action: PayloadAction<boolean>): IChangeLogState => {
      return {
        ...state,
        error: action.payload,
      }
    },
    setSelectedFilterData: (
      state: IChangeLogState,
      action: PayloadAction<IChangeLogSelectedFilter>,
    ): IChangeLogState => {
      const filterSelectedData = {
        ...state.filterSelectedData,
        sideModal: action.payload,
      }

      const filterCount = FilterHelper.getFilterCount(cloneDeep(state.sideModalData), filterSelectedData)
      return {
        ...state,
        filterSelectedData,
        filterCount,
        openFilterModal: false,
      }
    },
    clearFilter: (state: IChangeLogState): IChangeLogState => {
      const formFields: Array<IField> = FilterHelper.getFieldsByCategories(state.sideModalData.categories)
      return {
        ...state,
        sideModalData: {
          ...state.sideModalData,
          formFields,
        },
        filterCount: 0,
        filterSelectedData: {
          ...state.filterSelectedData,
          sideModal: {
            selectedCategories: state.sideModalData.categories,
            selectedFormFields: formFields,
            selectedUserField: {
              ...state.filterSelectedData.sideModal.selectedUserField,
              value: [],
            },
            viewOnlyFiles: false,
          },
        },
      }
    },
    setFilterFormFields: (state: IChangeLogState, action: PayloadAction<Array<IField>>): IChangeLogState => {
      return {
        ...state,
        sideModalData: {
          ...state.sideModalData,
          formFields: action.payload,
        },
      }
    },
    setChangeLogTableData: (state: IChangeLogState, action: PayloadAction<ITable>): IChangeLogState => {
      return {
        ...state,
        tableData: action.payload,
      }
    },
    setFilterData: (
      state: IChangeLogState,
      { payload }: PayloadAction<{ app: IApp; viewOnlyFiles: boolean }>,
    ): IChangeLogState => {
      const fieldType = payload.viewOnlyFiles ? FIELD_TYPES.FILE_UPLOAD : null
      const categories: Array<ICategory> = FilterHelper.getCategories(payload.app)
      const formFields: Array<IField> = FilterHelper.getFieldsByCategories(categories, fieldType)

      return {
        ...state,
        sideModalData: {
          ...state.sideModalData,
          categories,
          formFields,
        },
        filterSelectedData: {
          ...state.filterSelectedData,
          sideModal: {
            ...state.filterSelectedData.sideModal,
            selectedCategories: categories,
            selectedFormFields: formFields,
          },
        },
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setFilterDateRange,
  setOpenFilterModal,
  setChangeLogTableData,
  setFilterData,
  setFilterFormFields,
  setSelectedFilterData,
  clearFilter,
  setError,
  resetState,
} = changeLogSlice.actions

export default changeLogSlice.reducer
