// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bMef6{display:flex;gap:8px;flex-wrap:wrap}.Oi0JU{flex-direction:column}.snZKr{flex-direction:row}.aCSjg{display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./src/app/components/categorySection/formField/formFieldCheckbox/FormFieldCheckbox.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,OAAA,CACA,cAAA,CAGF,OACE,qBAAA,CAGF,OACE,kBAAA,CAGF,OACE,YAAA,CACA,kBAAA","sourcesContent":[".container {\n  display: flex;\n  gap: 8px;\n  flex-wrap: wrap;\n}\n\n.displayVertical {\n  flex-direction: column;\n}\n\n.displayHorizontal {\n  flex-direction: row;\n}\n\n.checkboxContainer {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `bMef6`,
	"displayVertical": `Oi0JU`,
	"displayHorizontal": `snZKr`,
	"checkboxContainer": `aCSjg`
};
export default ___CSS_LOADER_EXPORT___;
