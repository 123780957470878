// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nN_UH::part(button){min-width:90px}.oCG08{opacity:.5}.VB_fs::part(button){background-color:var(--wpp-grey-color-400)}`, "",{"version":3,"sources":["webpack://./src/app/components/toolbar/toolbarAction/ToolbarAction.module.scss"],"names":[],"mappings":"AACE,qBACE,cAAA,CAIJ,OACE,UAAA,CAIA,qBACE,0CAAA","sourcesContent":[".btnNextSubmit {\n  &::part(button) {\n    min-width: 90px;\n  }\n}\n\n.disabled {\n  opacity: 0.5;\n}\n\n.enableCommentMode {\n  &::part(button) {\n    background-color: var(--wpp-grey-color-400);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnNextSubmit": `nN_UH`,
	"disabled": `oCG08`,
	"enableCommentMode": `VB_fs`
};
export default ___CSS_LOADER_EXPORT___;
