import { AxiosResponse } from 'axios'
import _ from 'lodash'

import { getProjectMemberAPIPath } from 'config/apiPaths'
import IProject from 'interfaces/project/IProject'
import IProjectMember from 'interfaces/project/IProjectMember'
import AxiosService from 'lib/AxiosService'

export default class ProjectService {
  /**
   * If user is a project member
   * @param {string} accessToken
   * @param {string} projectId
   * @param {string} tenantId
   * @param {string} userEmail
   * @returns {IProject | null}
   */
  public static async getProjectInfo(
    accessToken: string,
    projectId: string,
    tenantId: string,
    userEmail: string,
  ): Promise<IProject | null> {
    try {
      const axiosService = new AxiosService(accessToken)
      const response: AxiosResponse<IProject> = await axiosService.get(getProjectMemberAPIPath(projectId), tenantId)

      let data: IProject | null = response.data

      const member: IProjectMember | undefined = data.members.find((member: IProjectMember) =>
        _.isEqual(_.toLower(member.email), _.toLower(userEmail)),
      )

      if (_.isUndefined(member)) {
        return null
      }
      return data
    } catch {
      return null
    }
  }
}
