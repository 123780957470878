import { AxiosError } from 'axios'
import HTTP_STATUS_CODES from 'http-status-codes'
import _ from 'lodash'

import { ERR_REQ_FIELD_APP, MSG_ALREADY_SAVED_DATA, MSG_SAVED_DATA } from 'config/constants'
import { APP_ACTIONS, TOAST_MESSAGE_TYPES } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import IParamAppAction from 'interfaces/questionnaire/params/IParamAppAction'
import FormValidationService from 'services/form/FormValidationService'
import QuestionnaireService from 'services/questionnaire/QuestionnaireService'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import { setErrorAlert, showAlert } from 'store/reducers/alertSlice'
import { setLoading, setState } from 'store/reducers/questionnaireSlice'

// Use to handle form action i.e submit, save progress, cancel, export, next or previous step
export default class ActionService {
  /**
   * Handle app actions i.e Submit, Save Progress, Cancel, Export
   * @param {IParamAppAction}
   */
  public static readonly handleAppAction = async (
    {
      accessToken,
      appAction,
      dispatch,
      displayMessage,
      appContext,
      questionnaireState,
      projectQuestionnaireId,
      t,
    }: IParamAppAction,
    callback: (message: TOAST_MESSAGE_TYPES, app: IApp | null) => void,
  ): Promise<IQuestionnaireState | null> => {
    const { app, activeStep } = questionnaireState
    if (!app) {
      return null
    }

    if (_.isEqual(appAction, APP_ACTIONS.CANCEL)) {
      const questState = {
        ...questionnaireState,
        appStatus: {
          ...questionnaireState.appStatus,
          openModal: questionnaireState.appStatus.isAppTouched,
        },
      }

      dispatch(setState(questState))
      return questState
    }

    if (!app.isAppEditor) {
      callback(TOAST_MESSAGE_TYPES.SUCCESS, app)
      return questionnaireState
    }

    const formValidationService = new FormValidationService()

    const updatedApp = _.isEqual(appAction, APP_ACTIONS.EXPORT)
      ? formValidationService.validateAppByCategoryId(app)
      : formValidationService.validateAppByCategoryId(app, app.categories[activeStep - 1].id)

    if (!updatedApp.isValid) {
      dispatch(
        showAlert({
          message: ERR_REQ_FIELD_APP,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      )
      const updatedState: IQuestionnaireState = { ...questionnaireState, app: updatedApp }
      dispatch(setState(updatedState))
      callback(TOAST_MESSAGE_TYPES.ERROR, updatedApp)
      return updatedState
    }

    let updatedCategory: ICategory = app.categories[activeStep - 1]
    const alertMessage = updatedCategory.dirty ? MSG_SAVED_DATA : MSG_ALREADY_SAVED_DATA
    const alertType = updatedCategory.dirty ? TOAST_MESSAGE_TYPES.SUCCESS : TOAST_MESSAGE_TYPES.INFORMATION

    dispatch(setLoading(true))

    try {
      switch (appAction) {
        case APP_ACTIONS.SAVE_AND_EXIT:
        case APP_ACTIONS.EXPORT:
        case APP_ACTIONS.SAVE_PROGRESS: {
          updatedCategory = await QuestionnaireService.onSaveProgress(
            accessToken,
            appContext,
            questionnaireState,
            projectQuestionnaireId,
            updatedCategory,
            dispatch,
          )
          if (displayMessage)
            dispatch(
              showAlert({
                message: alertMessage,
                type: alertType,
              }),
            )
          break
        }
        default:
          break
      }

      const questState: IQuestionnaireState = {
        ...questionnaireState,
        appStatus: {
          ...questionnaireState.appStatus,
          isAppTouched: false,
          openModal: false,
        },
        app: {
          ...app,
          categories: QuestionnaireService.updateCategoryById(app.categories, updatedCategory, updatedCategory.id),
        },
        loading: false,
      }

      dispatch(setState(questState))
      callback(TOAST_MESSAGE_TYPES.SUCCESS, questState.app)
      return questState
    } catch (error) {
      console.log(error)
      const err = error as AxiosError
      const errorMessage: string = _.isEqual(err.response?.status, HTTP_STATUS_CODES.FORBIDDEN)
        ? t('questionnaire.error.unauthorized_save')
        : t('questionnaire.error.save')

      dispatch(setErrorAlert(errorMessage))
      dispatch(setLoading(false))
      callback(TOAST_MESSAGE_TYPES.ERROR, app)
      return {
        ...questionnaireState,
        error: true,
      }
    }
  }
}
