import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'store'
import { setLoader } from 'store/reducers/loaderSlice'

// Custom Hook: Show/Hide spinner component
const useSpinner = function () {
  const dispatch = useDispatch()
  const loading = useSelector<RootState, boolean>((state: RootState) => state.loaderState)

  const setLoading = (flag: boolean) => dispatch(setLoader(flag))

  return {
    setLoading,
    loading,
  }
}

export default useSpinner
