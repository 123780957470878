import { WppEmptyError, WppGrid, WppTypography } from '@platform-ui-kit/components-library-react'
import React from 'react'
import { Trans } from 'react-i18next'

import styles from 'app/components/common/errorComponents/application/Application.module.scss'

/**
 * ApplicationError
 * Shows application error title and description
 */
const ApplicationError: React.FC = (): React.ReactElement => {
  return (
    <WppGrid container className={styles.errorContainer} rowSpacing={2}>
      <WppGrid item all={8} className={styles.error}>
        <WppEmptyError />
        <div className={styles.errorDescription}>
          <WppTypography type="m-strong" tag="p">
            <Trans>pages.error.title</Trans>
          </WppTypography>
          <WppTypography type="m-body">
            <Trans>pages.error.description</Trans>
          </WppTypography>
        </div>
      </WppGrid>
    </WppGrid>
  )
}

export default ApplicationError
