import { MentionData } from '@draft-js-plugins/mention'
import _ from 'lodash'
import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { Trans } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import FormFieldCommentEditDetail from 'app/components/categorySection/formField/formFieldComment/formFieldCommentDetail/editComment/EditComment'
import styles from 'app/components/categorySection/formField/formFieldComment/formFieldCommentDetail/FormFieldCommentDetail.module.scss'
import { WppButton, WppModal, WppProgressIndicator, WppTypography } from 'app/components/common'
import IComment from 'interfaces/field/fieldComment/IComment'
import IField from 'interfaces/field/IField'

interface IFormFieldCommentDetailProps {
  field: IField
  loadMoreComment: Function
  onEdit: (comment: IComment, config: Object, value: string, mentions: string[], cb: Function) => void
  onDelete: (comment: IComment, cb: Function) => void
  isCommentAllowed: boolean
  mentionOptions: MentionData[]
  setActiveAction: (value: boolean) => void
}

/**
 * Shows user comments for the field
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.loadMoreComment
 * @param {Function} props.onEdit
 * @param {Function} props.onDelete
 * @param {boolean} props.isCommentAllowed
 * @param {MentionData[]} props.mentionOptions
 * @param {Function} props.setActiveAction
 */
const FormFieldCommentDetail: React.FC<IFormFieldCommentDetailProps> = ({
  field,
  loadMoreComment,
  onEdit,
  onDelete,
  isCommentAllowed,
  mentionOptions,
  setActiveAction,
}: IFormFieldCommentDetailProps): React.ReactElement => {
  const { comments } = field.fieldComment
  const [open, setOpen] = useState<boolean>(false)
  const [selectedComment, setSelectedComment] = useState<IComment | null>(null)

  if (_.isEmpty(comments)) {
    return <></>
  }

  /**
   * Handles delete comment
   */
  const onDeleteComment = () => {
    if (!selectedComment) return
    onDelete(selectedComment, (error: any) => {
      if (_.isEmpty(error)) {
        setOpen(false)
      }
      setActiveAction(!_.isEmpty(error))
    })
  }

  return (
    <>
      <div id="scrollableDiv" className={styles.rootContainer}>
        <InfiniteScroll
          dataLength={comments.length}
          next={() => loadMoreComment()}
          className={styles.infiniteScrollContainer}
          inverse
          hasMore={field.fieldComment.hasMore}
          loader={<WppProgressIndicator />}
          scrollableTarget="scrollableDiv"
        >
          {_.orderBy(comments, ['createdAt'], ['desc']).map((inputComment: IComment) => (
            <FormFieldCommentEditDetail
              key={inputComment.id}
              inputComment={inputComment}
              isCommentAllowed={isCommentAllowed}
              mentionOptions={mentionOptions}
              onEdit={onEdit}
              setSelectedComment={setSelectedComment}
              selectedComment={selectedComment}
              setOpen={setOpen}
              setActiveAction={setActiveAction}
              data-testid="comment-detail"
            />
          ))}
        </InfiniteScroll>
      </div>
      {createPortal(
        <WppModal open={open} className={styles.modal}>
          <h3 slot="header">
            <Trans>comment.delete_confirmation_modal.title</Trans>
          </h3>
          <WppTypography tag="p" type="s-body" slot="body">
            <Trans>comment.delete_confirmation_modal.body</Trans>
          </WppTypography>
          <div slot="actions" className={styles.modalAction}>
            <WppButton
              variant="secondary"
              size="s"
              onClick={() => {
                setOpen(false)
                setSelectedComment(null)
                setActiveAction(false)
              }}
              data-testid="btn-cancel"
            >
              <Trans>app.button.cancel</Trans>
            </WppButton>
            <WppButton variant="destructive" size="s" data-testid="btn-delete" onClick={onDeleteComment}>
              <Trans>app.button.delete</Trans>
            </WppButton>
          </div>
        </WppModal>,
        document.body,
      )}
    </>
  )
}

export default FormFieldCommentDetail
