import { MentionData } from '@draft-js-plugins/mention'
import { useOs } from '@wppopen/react'
import { convertToRaw, EditorState } from 'draft-js'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import styles from 'app/components/categorySection/formField/formFieldComment/formFieldCommentEditor/FormFieldCommentEditor.module.scss'
import { WppActionButton, WppAvatar, WppTypography } from 'app/components/common'
import MentionEditor from 'app/components/common/mentionEditor/MentionEditor'
import { COMMENT_TEXT_EDITOR_MAX_LENGTH } from 'config/constants'
import { COMMENT_ACTION_TYPES } from 'config/enums'
import IField from 'interfaces/field/IField'
import FieldCommentService from 'services/formField/FieldCommentService'

interface IFormFieldCommentEditorProps {
  mentionOptions: MentionData[]
  field: IField
  handleCommentAction: Function
  popoverRef: React.RefObject<HTMLWppPopoverElement>
  setActiveAction: (value: boolean) => void
}

/**
 * Form field comment editor
 * Use to add new comment to the field
 * @param {object} props
 * @param {MentionData[]} props.mentionOptions
 * @param {IField} props.field
 * @param {Function} props.handleCommentAction
 * @param {(value: boolean) => void} props.setActiveAction
 * @param {React.RefObject<HTMLWppPopoverElement>} props.popoverRef
 */
const FormFieldCommentEditor: React.FC<IFormFieldCommentEditorProps> = ({
  mentionOptions,
  field,
  handleCommentAction,
  popoverRef,
  setActiveAction,
}: IFormFieldCommentEditorProps): React.ReactElement => {
  const { osContext } = useOs()
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const { firstname, lastname, avatarUrl, email } = osContext.userDetails
  const [charCount, setCharCount] = useState(0)

  /**
   * Handle new field comment
   */
  const onAddComment = () => {
    let comment: any = convertToRaw(editorState.getCurrentContent())

    handleCommentAction(
      field,
      COMMENT_ACTION_TYPES.ADD,
      (error: string) => {
        if (_.isEmpty(error)) {
          setEditorState(EditorState.createEmpty())
        }
      },
      convertToRaw(editorState.getCurrentContent()),
      undefined,
      editorState.getCurrentContent().getPlainText(),
      FieldCommentService.getMentions(comment),
    )
  }

  useEffect(() => {
    setCharCount(FieldCommentService.getCharCount(editorState))
  }, [editorState])

  return (
    <div className={styles.commentEditor}>
      <div className={styles.userInfo}>
        <WppAvatar name={email} withTooltip src={avatarUrl ?? ''} />
        <WppTypography type="s-strong" tag="p">
          {`${firstname} ${lastname}`}
        </WppTypography>
      </div>
      <MentionEditor
        mentionOptions={mentionOptions}
        setEditorState={setEditorState}
        editorState={editorState}
        isNewComment
      />
      <div className={styles.actions}>
        <WppActionButton
          data-testid="btn-cancel-comment"
          onClick={() => {
            setActiveAction(false)
            popoverRef.current?.closePopover()
          }}
          variant="secondary"
        >
          <Trans>app.button.cancel</Trans>
        </WppActionButton>
        <WppActionButton
          data-testid="btn-add-comment"
          onClick={() => onAddComment()}
          disabled={_.isEqual(charCount, 0) || _.gt(charCount, COMMENT_TEXT_EDITOR_MAX_LENGTH)}
        >
          <Trans>app.button.add_comment</Trans>
        </WppActionButton>
      </div>
    </div>
  )
}

export default FormFieldCommentEditor
